<template>
  <v-row gutters="5" class="mx-n1 mx-sm-n3">
    <v-container v-if="loading">
      <v-skeleton-loader
        class="mx-auto"
        min-height="250"
        v-bind="{ boilerplate: true, elevation: 2 }"
        type="image,list-item-three-line,card-heading"
      ></v-skeleton-loader>
    </v-container>
    <v-col
      v-else
      class="product-items"
      :lg="lg"
      :md="md"
      :sm="sm"
      v-for="product in dataArr"
      :key="product.id"
    >
      <product-grid-card
        :product="product"
        :is-rating-enable="isRatingEnable"
      ></product-grid-card>
    </v-col>
  </v-row>
</template>
<script>
import { HomeBlockMixins } from "@/mixins/HomeBlockMixins";
import ProductGridCard from "@/components/sites/common/ProductGridCard.vue";
export default {
  name: "ProductSection",
  mixins: [HomeBlockMixins],
  components: {
    ProductGridCard,
  },
  props: {
    grid: Number,
  },
  computed: {
    lg() {
      if (this.grid == 6) {
        return 4;
      }
      return 2;
    },
    md() {
      if (this.grid == 6) {
        return 6;
      }
      return 3;
    },
    sm() {
      if (this.grid == 6) {
        return 6;
      }
      return 4;
    },
  },
  data() {
    return {
      isRatingEnable: true,
    };
  },
};
</script>
