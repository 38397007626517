<template>
  <v-btn
    :fab="setIconStyle()"
    :icon="!setIconStyle()"
    :elevation="getElevation()"
    :color="favorited ? 'brand' : ''"
    :class="favorited ? 'white--text' : 'grey--text'"
    @click="toggleFavorite"
    aria-label="Add To Faborite"
    role="button"
  >
    <slot>
      <v-icon> mdi-heart </v-icon>
    </slot>
  </v-btn>
</template>

<script>
export default {
  name: "WishListBtn",
  data() {
    return {
      favorited: false,
    };
  },
  props: {
    isFavorite: Boolean,
    productId: Number,
    isElevation: Boolean,
  },
  created() {
    if (this.isFavorite) {
      this.favorited = this.isFavorite;
    }
  },
  methods: {
    getElevation() {
      if (!this.isElevation) return 0;

      if (this.favorited) {
        return 2;
      } else {
        return 2;
      }
    },
    setIconStyle() {
      if (!this.isElevation) return false;
      return true;
    },
    toggleFavorite() {
      let check = this.$root.checkAuth();
      if (check) return;

      this.$http
        .post(
          this.$store.state.Configs.toggleFavorite.replace(
            ":id",
            this.productId
          )
        )
        .then((res) => {
          this.favorited = res.data.ResponseData;
          if (!this.favorited) {
            this.Fire.$emit("wish-list-deleted", this.productId);
          }
          this.$store.commit("update_favorite_count", this.favorited);
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>
