var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.menus && _vm.menus.length)?_c('ul',[_vm._l((_vm.menus),function(menu){return _c('li',{key:menu.Id},[(menu.Link)?_c('router-link',{class:{ 'router-link-exact-active': _vm.selectedMenuId == menu.Id },style:({ color: menu.Color }),attrs:{"to":menu.Link}},[_c('img',{attrs:{"src":_vm.$root.generateMediaImageUrl(menu.ImageUrl),"alt":"menu icon"}}),_c('span',{domProps:{"textContent":_vm._s(menu.Name)}})]):_vm._e()],1)}),(_vm.promotionalCategories && _vm.promotionalCategories.length)?_vm._l((_vm.promotionalCategories),function(promotionalCategory,index){return _c('li',{key:'p-' + index},[_c('router-link',{class:{
          'router-link-exact-active':
            _vm.selectedMenuId == 'm' + promotionalCategory.Id,
        },style:({ color: _vm.firstMenu.Color || 'white' }),attrs:{"to":{
          name: 'ProductSearch',
          params: { slug: _vm.slugify(promotionalCategory.Name) },
          query: {
            MenuType: 'm' + promotionalCategory.Id,
            Category: promotionalCategory.Id,
          },
        }}},[_c('img',{attrs:{"src":_vm.$root.generateMediaImageUrl(promotionalCategory.HeaderIconUrl),"alt":"menu icon"}}),_c('span',{domProps:{"innerHTML":_vm._s(
            promotionalCategory.Name +
            '&nbsp; &nbsp; &nbsp;' +
            _vm.getBadge(promotionalCategory.Badge)
          )}})])],1)}):_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }