<template>
  <v-flex class="all-categories">
    <v-flex class="cat-text pointer" @mouseover="loadCategory()">
      <span>{{ $t("All Categories") }}</span>
      <v-icon color="white" size="22" v-if="loading"
        >mdi-spin mdi-loading</v-icon
      >
      <v-icon color="white" size="22" v-else>mdi-chevron-down</v-icon>
    </v-flex>

    <v-layout
      v-if="allCategories && allCategories.length"
      class="cat-holder flex-grow-0"
      :class="{ 'hide-cat': hideCat }"
    >
      <ul class="parent-category">
        <li
          v-for="parentCategory in allCategories"
          :key="parentCategory.Id"
          @click="hideCat = true"
        >
          <router-link
            :to="{
              name: 'ProductSearch',
              params: { slug: slugify(parentCategory.Name) },
              query: { ParentCategoryId: parentCategory.Id },
            }"
          >
            <template v-if="parentCategory.IconUrl">
              <i
                :style="
                  'background:url(' +
                  $root.generateMediaImageUrl(
                    encodeURIComponent(parentCategory.IconUrl)
                  ) +
                  ')'
                "
              ></i>
            </template>

            <template v-else>
              <i class="nfi-no-icon"></i>
            </template>
            <span v-text="parentCategory.Name"></span>
          </router-link>
          <div class="sub-categories" v-if="parentCategory.Children.length > 0">
            <ul class="cat-list">
              <li v-for="subcat in parentCategory.Children" :key="subcat.Id">
                <router-link
                  :to="{
                    name: 'ProductSearch',
                    params: { slug: slugify(subcat.Name) },
                    query: { ParentCategoryId: subcat.Id },
                  }"
                  v-text="subcat.Name"
                ></router-link>
                <div class="sub-sub-cat">
                  <router-link
                    v-for="subsubcat in subcat.Children"
                    :key="subsubcat.Id"
                    v-text="subsubcat.Name"
                    :to="
                      subsubcat.Children.length > 0
                        ? {
                            name: 'ProductSearch',
                            params: { slug: slugify(subsubcat.Name) },
                            query: {
                              ParentCategoryId: subsubcat.Id,
                            },
                          }
                        : {
                            name: 'ProductSearch',
                            params: { slug: slugify(subcat.Name) },
                            query: {
                              ParentCategoryId: subcat.Id,
                              Category: subsubcat.Id,
                            },
                          }
                    "
                  ></router-link>
                </div>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </v-layout>
  </v-flex>
</template>

<script>
import { SlugifyMixin } from "@/mixins/SlugifyMixin";
export default {
  name: "AllCategory",
  mixins: [SlugifyMixin],
  data() {
    return {
      hideCat: false,
      loading: false,
    };
  },
  computed: {
    allCategories: function () {
      return this.$store.state.allCategories.Data;
    },
  },
  methods: {
    loadCategory() {
      this.hideCat = false;

      if (this.loading || (this.allCategories && this.allCategories.length)) {
        return true;
      }

      this.loading = true;

      this.$store.dispatch("getAllCategories").then(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/all_categories.scss";
</style>
