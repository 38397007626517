import { findIndex } from "lodash";

const STORE_MUTATION = {
  token_request(state) {
    state.tokenStatus = "loading";
  },
  token_success(state, token) {
    state.tokenStatus = "success";
    state.token = token;
    localStorage.setItem("token", token);
  },
  token_error(state) {
    state.tokenStatus = "error";
    state.token = "";
    localStorage.removeItem("token");
  },
  auth_request(state) {
    state.loading = true;
  },
  auth_success(state, auth) {
    state.loading = false;
    state.auth = auth;
    localStorage.setItem("auth", auth);
  },
  auth_error(state) {
    //  state.auth = ''
    state.loading = false;
    //localStorage.removeItem('auth')
  },
  logout(state) {
    state.loading = false;
    state.token = "";
    state.tokenStatus = "";
    state.auth = null;
    localStorage.removeItem("token");
    localStorage.removeItem("auth");
    localStorage.removeItem("app-carts");
    localStorage.removeItem("loginRedirect");
  },
  xhr_request(state) {
    state.loading = true;
  },
  xhr_success(state) {
    state.loading = false;
  },
  set_flash(state, obj) {
    state.flash = obj;
    // var timer = state.flash.miliseconds || 8000;
    // setTimeout(() => {
    //   state.flash = {};
    // }, timer);
  },
  set_lang(state, lang) {
    state.lang = lang;
    localStorage.setItem("lang", lang);
  },
  set_site_settings(state, obj) {
    state.siteSettings = obj;
  },
  remove_site_settings(state) {
    state.siteSettings = {};
  },
  set_item_groups(state, arr) {
    state.itemGroups = arr;
  },
  remove_item_groups(state) {
    state.itemGroups = [];
  },
  set_all_parent_categories(state, arr) {
    state.allParentCategories = arr;
  },
  remove_all_parent_categories(state) {
    state.allParentCategories = [];
  },
  set_all_categories(state, arr) {
    state.allCategories = arr;
  },
  remove_all_categories(state) {
    state.allCategories = [];
  },
  set_locations(state, arr) {
    state.locations = arr;
  },
  remove_locations(state) {
    state.locations = [];
  },
  get_existing_carts(state) {
    let cartItems = JSON.parse(localStorage.getItem("app-carts"));
    if (cartItems) {
      state.carts = cartItems;
    } else {
      state.carts = [];
    }
  },
  set_carts(state, EcommerceItems) {
    let cartItems = JSON.parse(localStorage.getItem("app-carts"));
    if (cartItems) {
      state.carts = cartItems;
    }
    state.carts.Ecommerce = EcommerceItems;
    localStorage.setItem("app-carts", JSON.stringify(state.carts));
  },
  cartQuantityCountUpdate(state, cartItems) {
    state.profileObj.CartQuantityCount = cartItems.reduce(
      (partial_sum, a) => partial_sum + a.CartQuantity,
      0
    );
  },
  update_carts(state, productObj) {
    let carts = localStorage.getItem("app-carts");
    if (carts) {
      state.carts = JSON.parse(carts);
    }

    if (productObj.CartType == "BuyNow") {
      state.carts.BuyNow = [];
      state.carts.BuyNow.push(productObj);
    } else {
      var index = findIndex(state.carts.Ecommerce, {
        ItemId: productObj.ItemId,
      });
      if (index !== -1) {
        state.carts.Ecommerce[index].CartQuantity = productObj.CartQuantity;
      } else {
        state.carts.Ecommerce.push(productObj);
      }
      state.profileObj.CartQuantityCount = state.carts.Ecommerce.reduce(
        (partial_sum, a) => partial_sum + a.CartQuantity,
        0
      );
    }
    localStorage.setItem("app-carts", JSON.stringify(state.carts));
  },
  delete_from_carts(state, productObj) {
    if (productObj.CartType == "BuyNow") {
      state.carts.BuyNow = [];
    } else {
      var index = findIndex(state.carts.Ecommerce, {
        ItemId: productObj.ItemId,
      });
      if (index !== -1) {
        state.carts.Ecommerce.splice(index, 1);
        state.profileObj.CartQuantityCount = state.carts.Ecommerce.reduce(
          (partial_sum, a) => partial_sum + a.CartQuantity,
          0
        );
      }
    }
    localStorage.setItem("app-carts", JSON.stringify(state.carts));
  },
  empty_carts(state, cartType) {
    state.carts[cartType] = [];
    var index = findIndex(state.carts.Coupon, { Type: cartType });
    if (index !== -1) {
      state.carts.Coupon.splice(index, 1);
    }
    if (cartType != "BuyNow") {
      state.profileObj.CartQuantityCount = 0;
    }
    localStorage.setItem("app-carts", JSON.stringify(state.carts));
  },
  update_profile(state, profileObj) {
    state.profileObj = profileObj;
  },
  update_notification_count(state, UnReadNotificationCountObj) {
    if (UnReadNotificationCountObj.isTotal) {
      state.profileObj.UnReadNotificationCount =
        UnReadNotificationCountObj.count;
    } else {
      state.profileObj.UnReadNotificationCount =
        state.profileObj.UnReadNotificationCount +
        UnReadNotificationCountObj.count;
    }
  },
  update_favorite_count(state, boolValue) {
    let previousCount = state.profileObj.FavoriteItemCount || 0;
    if (boolValue) {
      state.profileObj.FavoriteItemCount = previousCount + 1;
    } else {
      state.profileObj.FavoriteItemCount = previousCount - 1;
    }
  },
  update_meta(state, metaObj) {
    state.metaObj = { ...state.metaObj, ...metaObj };
  },
  update_coupon(state, couponObj) {
    state.carts.Coupon = couponObj;
    localStorage.setItem("app-carts", JSON.stringify(state.carts));
  },
  delete_coupon(state, couponObj) {
    if (state.carts.Coupon.length) {
      var index = findIndex(state.carts.Coupon, couponObj);
      if (index !== -1) {
        state.carts.Coupon.splice(index, 1);
        localStorage.setItem("app-carts", JSON.stringify(state.carts));
      }
    }
  },
  update_profile_menu_selected_tab(state, selected) {
    state.profileMenuTabSelected = selected;
    localStorage.setItem("profile-menu-selected-tab", selected);
  },

  set_success_order_id(state, orderId) {
    state.successOrderId = orderId;
    localStorage.setItem("successOrderId", orderId);
  },
  removeSuccessOrderId() {
    localStorage.removeItem("successOrderId");
  },

  setPickupAddressId(state, id) {
    state.selectedPickupAddressId = id;
    localStorage.setItem("selectedPickupAddressId", id);
  },
  removePickupAddressId(state) {
    state.selectedPickupAddressId = null;
    localStorage.removeItem("selectedPickupAddressId");
  },
  setBillingAddressId(state, id) {
    state.selectedBillingAddressId = id;
    localStorage.setItem("selectedBillingAddressId", id);
  },
  setSeoFooterDescription(state, des) {
    state.seoFooterDescription = des;
  }
};

export default STORE_MUTATION;
