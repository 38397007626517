<template>
  <v-flex class="monile-menu">
    <v-layout class="monile-menu-top align-center justify-space-between">
      <v-layout class="flex-grow-0">
        <!-- menu bar -->
        <div
          @click.stop="(drawer = !drawer), loadAllParentCategories()"
          class="menu-bar"
        ></div>

        <!-- require('@/assets/images/logo.png') -->

        <router-link :to="{ name: 'Home' }">
          <img
            class="mobile-logo"
            alt="Nilam Logo"
            :src="
              $root.generateMediaImageUrl(
                siteSettings.MobileLogoPath,
                'logo-bg.png'
              )
            "
          />
        </router-link>
      </v-layout>

      <v-layout class="flex-grow-0">
        <v-flex class="top-header-widgets">
          <v-flex
            @click="show_search = !show_search"
            class="header-widgets-icon"
          >
            <v-icon size="18">mdi-magnify</v-icon>
          </v-flex>

          <!-- Mobile search -->
          <v-flex
            :class="{ 'show-search': show_search }"
            class="search-product"
          >
            <head-search></head-search>

            <span @click="show_search = !show_search" class="close d-flex">
              <v-icon class="ma-auto">mdi-window-close</v-icon>
            </span>
          </v-flex>
        </v-flex>

        <v-flex class="top-header-widgets">
          <router-link
            :to="{
              name: 'Checkout',
              params: { cartType: 'ecommerce' },
            }"
          >
            <v-flex class="header-widgets-icon">
              <v-icon>mdi-cart</v-icon>
              <v-flex
                class="count"
                v-if="
                  isLoggedIn && Object.keys(carts).length && carts.Ecommerce
                "
                v-text="carts.Ecommerce.length"
              ></v-flex>
            </v-flex>
          </router-link>
        </v-flex>

        <!-- Accounts dropdown -->
        <v-flex class="top-header-widgets">
          <v-menu rounded="0" offset-y transition="slide-x-transition" left>
            <template v-slot:activator="{ on, attrs }">
              <v-flex v-bind="attrs" v-on="on" class="pointer">
                <v-flex class="header-widgets-icon">
                  <v-avatar
                    size="24"
                    v-if="isLoggedIn && profileObj.ProfileImageUrl"
                  >
                    <v-img
                      width="24"
                      height="24"
                      contain
                      :src="
                        $root.generateMediaImageUrl(profileObj.ProfileImageUrl)
                      "
                      alt="profile image"
                    ></v-img>
                  </v-avatar>
                  <v-icon v-else>mdi-account</v-icon>
                  <v-icon>mdi-chevron-down</v-icon>
                </v-flex>
                <span
                  class="text-truncate sign-text"
                  v-if="isLoggedIn"
                  v-text="profileObj.FullName"
                ></span>
                <span v-else v-text="$t('Sign In')"></span>
              </v-flex>
            </template>
            <v-list class="login-dropdown" flat dense>
              <v-list-item>
                <v-list-item-title>
                  <v-sheet
                    v-if="isLoggedIn"
                    tile
                    class="user-box mb-3 d-flex align-center"
                  >
                    <v-avatar size="75">
                      <v-img
                        width="24"
                        height="24"
                        contain
                        :src="
                          $root.generateMediaImageUrl(
                            profileObj.ProfileImageUrl
                          )
                        "
                        alt="profile image"
                      ></v-img>
                    </v-avatar>
                    <v-sheet class="user-title pl-4">
                      <span>Hello,</span>
                      <h4 v-text="profileObj.FullName"></h4>
                      <v-icon size="17" color="gray darken-2"
                        >mdi-phone-in-talk</v-icon
                      >
                      <span
                        class="profile-info"
                        v-text="profileObj.Mobile"
                      ></span>
                      <img
                        v-if="isSeller"
                        class="verified"
                        alt="Verified Seller"
                        :src="require('@/assets/images/verified-seller.png')"
                      />
                    </v-sheet>
                  </v-sheet>
                  <v-flex
                    class="pb-3 log-btns d-flex justify-space-between"
                    v-if="isLoggedIn"
                  >
                    <v-btn
                      width="137"
                      :to="{ name: 'Logout' }"
                      tile
                      depressed
                      class="primary white--text text-none"
                      >{{ $t("Logout") }}</v-btn
                    >
                  </v-flex>
                  <v-flex v-else>
                    <div class="pb-3 text-center">
                      <span>Welcome To Nilam</span>
                    </div>

                    <v-flex class="pb-6 px-5 d-flex justify-space-between">
                      <v-btn
                        width="110"
                        :to="{ name: 'Register' }"
                        tile
                        depressed
                        class="primary white--text text-none mr-1"
                        >{{ $t("Sign Up") }}</v-btn
                      >
                      <v-btn
                        width="110"
                        :to="{ name: 'Login' }"
                        tile
                        depressed
                        class="brand white--text text-none"
                        >Sign In</v-btn
                      >
                    </v-flex>
                  </v-flex>
                </v-list-item-title>
              </v-list-item>

              <v-list-item-group
                class="account-menus"
                color="brand"
                v-if="isLoggedIn"
                v-model="selectedItem"
              >
                <v-divider></v-divider>
                <v-flex
                  v-for="(item, i) in profileMenus.common"
                  :key="'common-' + i"
                >
                  <v-list-item :to="{ name: `${item.link}` }">
                    <v-list-item-icon class="mr-2">
                      <v-icon size="19" v-text="item.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.text"></v-list-item-title>
                    </v-list-item-content>
                    <span
                      v-if="item.count"
                      v-text="profileObj[item.count]"
                    ></span>
                  </v-list-item>
                </v-flex>
                <h5
                  class="pt-5 pb-2 px-4 text-uppercase grey--text darken-1 font-weight-regular"
                  v-text="profileMenus.customer.title"
                ></h5>
                <v-divider></v-divider>
                <v-flex
                  v-for="(item, i) in profileMenus.customer.menuArr"
                  :key="'customer-' + i"
                >
                  <v-list-item
                    :to="{ name: `${item.link}`, params: item.params }"
                  >
                    <v-list-item-icon class="mr-2">
                      <v-icon size="19" v-text="item.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.text"></v-list-item-title>
                    </v-list-item-content>
                    <span
                      v-if="item.count"
                      v-text="profileObj[item.count]"
                    ></span>
                  </v-list-item>
                </v-flex>
                <h5
                  class="pt-5 pb-2 px-4 text-uppercase grey--text darken-1 font-weight-regular"
                  v-text="profileMenus.seller.title"
                ></h5>
                <v-divider></v-divider>
                <v-flex
                  v-for="(item, i) in profileMenus.seller.menuArr"
                  :key="'seller-' + i"
                >
                  <v-list-item :to="{ name: `${item.link}` }">
                    <v-list-item-icon class="mr-2">
                      <v-icon size="19" v-text="item.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.text"></v-list-item-title>
                    </v-list-item-content>
                    <span
                      v-if="item.count"
                      v-text="profileObj[item.count]"
                    ></span>
                  </v-list-item>
                </v-flex>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </v-flex>
      </v-layout>
    </v-layout>

    <v-flex class="mobile-drawer">
      <v-btn
        v-if="drawer"
        color="white"
        depressed
        class="close-drawer"
        :class="{ 'close-drawer-active': drawer }"
        min-width="35"
        max-width="45"
        max-height="35"
        tile
        dri
        @click.stop="drawer = !drawer"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-scroll-x-reverse-transition>
        <!-- <v-btn
          tile
          depressed
          width="40"
          min-width="20"
          class="drawer-toggle"
          v-if="drawer"
          @click.stop="drawer = !drawer"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>-->
      </v-scroll-x-reverse-transition>
      <v-navigation-drawer v-model="drawer" temporary app>
        <v-list-item class="pa-3" v-if="isLoggedIn">
          <v-list-item-avatar>
            <v-img
              alt="User Blank Photo"
              :src="require('@/assets/images/user-blank-photo.jpg')"
            ></v-img>
          </v-list-item-avatar>

          <v-list-item-title>
            {{ profileObj.FullName }}
            <br />
            <router-link :to="{ name: 'Logout' }" class="sign-status"
              >Logout</router-link
            >
          </v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>

        <v-list-item class="py-2">
          <v-btn
            width="100%"
            height="38"
            color="primary"
            tile
            depressed
            class="post-promotion"
            :to="{ name: 'postProductStep1' }"
          >
            <v-icon size="15">mdi-plus-thick</v-icon>
            {{ $t("Add Product") }}
          </v-btn>
        </v-list-item>
        <v-divider></v-divider>
        <v-flex class="category-menu">
          <header-menu v-if="menus && menus.length"></header-menu>
        </v-flex>
        <v-flex class="language px-3 py-2" v-if="false">
          Language:
          <v-btn color="primary" tile depressed class="text-none mx-3 px-0">
            <language></language>
          </v-btn>
        </v-flex>

        <v-divider></v-divider>
        <v-flex
          class="mobile-category"
          v-if="allParentCategories && allParentCategories.length"
        >
          <v-flex class="all-categories">
            <v-flex class="cat-text pointer">All Categories</v-flex>
            <v-flex class="cat-holder flex-grow-0">
              <ul class="parent-category">
                <li
                  v-for="(parentCategory, index) in allParentCategories"
                  :key="index"
                >
                  <router-link
                    :to="{
                      name: 'ProductSearch',
                      params: { slug: slugify(parentCategory.Name) },
                      query: { parentCategoryId: parentCategory.Id },
                    }"
                  >
                    <template v-if="parentCategory.IconUrl">
                      <i
                        :style="
                          'background:url(' +
                          $root.generateMediaImageUrl(
                            encodeURIComponent(parentCategory.IconUrl)
                          ) +
                          ')'
                        "
                      ></i>
                    </template>

                    <template v-else>
                      <i class="nfi-no-icon"></i>
                    </template>
                    <span v-text="parentCategory.Name"></span>
                  </router-link>
                </li>
              </ul>
            </v-flex>
          </v-flex>
        </v-flex>
      </v-navigation-drawer>
    </v-flex>
  </v-flex>
</template>

<script>
import HeadSearch from "@/components/sites/common/HeadSearch";
import HeaderMenu from "@/components/sites/common/HeaderMenu";
import Language from "@/components/sites/common/Language";
import { SlugifyMixin } from "@/mixins/SlugifyMixin";
//import AllCategories from "@/components/sites/common/AllCategories";
export default {
  name: "MobileMenu",
  mixins: [SlugifyMixin],
  components: {
    HeadSearch,
    HeaderMenu,
    Language,
    // AllCategories,
  },

  data() {
    return {
      drawer: false,
      show_search: false,
      selectedItem: "",
    };
  },
  computed: {
    siteSettings() {
      return this.$store.state.siteSettings;
    },
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    carts() {
      return this.$store.state.carts;
    },
    profileObj() {
      return this.$store.state.profileObj;
    },
    profileMenus() {
      return this.$store.state.Configs.profileMenus;
    },
    menus: function () {
      return this.$store.state.itemGroups;
    },
    isSeller() {
      if (this.$auth && this.$auth.user) {
        return this.$auth.user.IsVerified;
      }
      return false;
    },
    allParentCategories: function () {
      return this.$store.state.allParentCategories;
    },
  },
  methods: {
    loadAllParentCategories() {
      if (this.allParentCategories && this.allParentCategories.length) {
        return true;
      }
      this.$store.dispatch("getAllParentCategories");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/mobile_header.scss";
</style>
