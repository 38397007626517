var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-flex',{staticClass:"all-categories"},[_c('v-flex',{staticClass:"cat-text pointer",on:{"mouseover":function($event){return _vm.loadCategory()}}},[_c('span',[_vm._v(_vm._s(_vm.$t("All Categories")))]),(_vm.loading)?_c('v-icon',{attrs:{"color":"white","size":"22"}},[_vm._v("mdi-spin mdi-loading")]):_c('v-icon',{attrs:{"color":"white","size":"22"}},[_vm._v("mdi-chevron-down")])],1),(_vm.allCategories && _vm.allCategories.length)?_c('v-layout',{staticClass:"cat-holder flex-grow-0",class:{ 'hide-cat': _vm.hideCat }},[_c('ul',{staticClass:"parent-category"},_vm._l((_vm.allCategories),function(parentCategory){return _c('li',{key:parentCategory.Id,on:{"click":function($event){_vm.hideCat = true}}},[_c('router-link',{attrs:{"to":{
            name: 'ProductSearch',
            params: { slug: _vm.slugify(parentCategory.Name) },
            query: { ParentCategoryId: parentCategory.Id },
          }}},[(parentCategory.IconUrl)?[_c('i',{style:('background:url(' +
                _vm.$root.generateMediaImageUrl(
                  encodeURIComponent(parentCategory.IconUrl)
                ) +
                ')')})]:[_c('i',{staticClass:"nfi-no-icon"})],_c('span',{domProps:{"textContent":_vm._s(parentCategory.Name)}})],2),(parentCategory.Children.length > 0)?_c('div',{staticClass:"sub-categories"},[_c('ul',{staticClass:"cat-list"},_vm._l((parentCategory.Children),function(subcat){return _c('li',{key:subcat.Id},[_c('router-link',{attrs:{"to":{
                  name: 'ProductSearch',
                  params: { slug: _vm.slugify(subcat.Name) },
                  query: { ParentCategoryId: subcat.Id },
                }},domProps:{"textContent":_vm._s(subcat.Name)}}),_c('div',{staticClass:"sub-sub-cat"},_vm._l((subcat.Children),function(subsubcat){return _c('router-link',{key:subsubcat.Id,attrs:{"to":subsubcat.Children.length > 0
                      ? {
                          name: 'ProductSearch',
                          params: { slug: _vm.slugify(subsubcat.Name) },
                          query: {
                            ParentCategoryId: subsubcat.Id,
                          },
                        }
                      : {
                          name: 'ProductSearch',
                          params: { slug: _vm.slugify(subcat.Name) },
                          query: {
                            ParentCategoryId: subcat.Id,
                            Category: subsubcat.Id,
                          },
                        }},domProps:{"textContent":_vm._s(subsubcat.Name)}})}),1)],1)}),0)]):_vm._e()],1)}),0)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }