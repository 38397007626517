import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
//import "./sass/app.scss";

import axios from "axios";
Vue.prototype.$http = axios;

const token = localStorage.getItem("token");
if (token) {
  Vue.prototype.$http.defaults.headers.common["Authorization"] =
    "Bearer " + token;
}

const lang = localStorage.getItem("lang");
if (lang) {
  Vue.prototype.$http.defaults.headers.common["Accept-Language"] = lang;
} else {
  store.commit("set_lang", process.env.VUE_APP_I18N_LOCALE || "en-US");
}

// for multi image uploader
import VueLazyload from "vue-lazyload";
Vue.use(VueLazyload);

import VueMeta from "vue-meta";
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

// for social sharing
import VueSocialSharing from "vue-social-sharing";
Vue.use(VueSocialSharing);

import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
import { ValidationObserver } from "vee-validate";
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

import "intl-tel-input/build/css/intlTelInput.css";
import intlTelInput from "intl-tel-input";
Vue.prototype.intlTelInput = intlTelInput;

import {
  map,
  filter,
  find,
  each,
  camelCase,
  findIndex,
  first,
  reduce,
  debounce,
  findLastIndex,
  groupBy,
  reject,
  chunk,
  castArray,
  isEqual,
  compact,
  flatten,
  join,
} from "lodash";
let lodash = {
  map,
  filter,
  find,
  each,
  camelCase,
  findIndex,
  first,
  reduce,
  debounce,
  findLastIndex,
  groupBy,
  reject,
  chunk,
  castArray,
  isEqual,
  compact,
  flatten,
  join,
};
Vue.prototype._ = lodash;

import VueCountdown from "@chenfengyuan/vue-countdown";
Vue.component(VueCountdown.name, VueCountdown);

Vue.config.productionTip = false;
Vue.prototype.$asset_root = process.env.VUE_APP_ASSET_ROOT;
Vue.prototype.Fire = new Vue();

import Auth from "./plugins/auth";
Vue.use(Auth);

import NotificationHub from "./plugins/notification-hub";
Vue.use(NotificationHub);

import VuetifyConfirm from "vuetify-confirm";
Vue.use(VuetifyConfirm, { vuetify });

new Vue({
  router,
  store,
  vuetify,
  i18n,
  ...App,
}).$mount("#app");
