import Vue from "vue";
import Vuex from "vuex";

import STORE_MUTATION from "./store_mutation";

import STORE_ACTIONS from "./store_actions";

import * as Configs from "../configs";

import * as routeConfig from "../routeConfig";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    Configs: { ...routeConfig, ...Configs },
    tokenStatus: "",
    authStatus: "",
    flash: {},
    lang: localStorage.getItem("lang") || "en-US",
    token: localStorage.getItem("token") || "",
    auth: localStorage.getItem("auth") || null,
    loading: false,
    wishListedIdsArr: [],
    wishList: [],
    siteSettings: {},
    itemGroups: [],
    allParentCategories: [],
    allCategories: [],
    locations: [],
    successOrderId: localStorage.getItem("successOrderId") || null,
    selectedPickupAddressId:
      localStorage.getItem("selectedPickupAddressId") || null,
    selectedBillingAddressId:
      localStorage.getItem("selectedBillingAddressId") || 0,
    carts: {
      Ecommerce: [],
      BuyNow: [],
      Coupon: [],
    },
    common: {},
    profileObj: {
      FavoriteItemCount: 0,
      UnReadNotificationCount: 0,
      CartQuantityCount: 0,
    },
    metaObj: {},
    profileMenuTabSelected:
      localStorage.getItem("profile-menu-selected-tab") || "tab-customer",
    seoFooterDescription: ""
  },
  mutations: STORE_MUTATION,
  actions: STORE_ACTIONS,
  getters: {
    hasClientToken: (state) => !!state.token,
    tokenStatus: (state) => state.tokenStatus,
    isLoggedIn: (state) => !!state.auth,
    isLoading: (state) => state.loading,
    flash: (state) => state.flash,
    lang: (state) => state.lang,
  },
  modules: {},
});
