import axios from "axios";
import { format } from "date-fns";

const STORE_ACTIONS = {
  removeExistingItem(key) {
    if (localStorage.getItem(key) === null) {
      return false;
    } else {
      localStorage.removeItem(key);
      return true;
    }
  },
  getToken({ state, commit, getters }) {
    return new Promise((resolve, reject) => {
      if (getters.hasClientToken) {
        resolve("token alrady exits");
        return;
      }

      const data = Object.keys(state.Configs.clientInfo)
        .map(
          (key) => `${key}=${encodeURIComponent(state.Configs.clientInfo[key])}`
        )
        .join("&");

      commit("token_request");
      axios({
        method: "post",
        url: state.Configs.getToken,
        data: data,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
        .then((resp) => {
          const token = resp.data.access_token;
          axios.defaults.headers.common["Authorization"] = "Bearer " + token;
          commit("token_success", token);
          resolve(resp);
        })
        .catch((err) => {
          commit("token_error");
          reject(err);
        });
    });
  },
  register({ commit, getters, state }, user) {
    return new Promise((resolve, reject) => {
      if (getters.isLoggedIn) {
        resolve("auth alrady exits");
        return;
      }

      var data = { ...state.Configs.clientInfo, ...user };

      commit("xhr_request");
      axios({
        url: state.Configs.register,
        data: data,
        method: "POST",
      })
        .then((resp) => {
          commit("xhr_success");
          resolve(resp);
        })
        .catch((err) => {
          commit("xhr_success");
          reject(err);
        });
    });
  },
  login({ state, commit, getters }, user) {
    return new Promise((resolve, reject) => {
      if (getters.isLoggedIn && JSON.parse(state.auth).AccountVerifiedAt) {
        resolve("auth alrady exits");
        return;
      }

      var params = { ...state.Configs.clientInfo, ...user };

      const data = Object.keys(params)
        .map((key) => `${key}=${encodeURIComponent(params[key])}`)
        .join("&");

      commit("auth_request");
      axios({
        method: "post",
        url: state.Configs.getToken,
        data: data,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
        .then((resp) => {
          const token = resp.data.access_token;

          commit("token_success", token);

          axios.defaults.headers.common["Authorization"] = "Bearer " + token;

          const auth = resp.data.User;

          commit("auth_success", auth);
          resolve(resp);
        })
        .catch((err) => {
          commit("auth_error");
          reject(err);
        });
    });
  },
  logout({ commit }) {
    return new Promise((resolve) => {
      commit("logout");
      delete axios.defaults.headers.common["Authorization"];
      resolve();
    });
  },
  getSiteSettings({ state, commit }) {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: state.Configs.siteSettings,
      })
        .then((resp) => {
          commit("set_site_settings", resp.data);
          resolve(resp);
        })
        .catch((err) => {
          commit("remove_site_settings");
          reject(err);
        });
    });
  },
  getItemGroups({ state, commit }) {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: state.Configs.getItemGroups,
      })
        .then((resp) => {
          commit("set_item_groups", resp.data);
          resolve(resp);
        })
        .catch((err) => {
          commit("remove_item_groups");
          reject(err);
        });
    });
  },
  getAllParentCategories({ state, commit }) {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: state.Configs.getAllParentCategories,
      })
        .then((resp) => {
          commit("set_all_parent_categories", resp.data);
          resolve(resp);
        })
        .catch((err) => {
          commit("remove_all_parent_categories");
          reject(err);
        });
    });
  },
  getAllCategories({ state, commit }) {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: state.Configs.getAllCategories,
      })
        .then((resp) => {
          commit("set_all_categories", resp.data);
          resolve(resp);
        })
        .catch((err) => {
          commit("remove_all_categories");
          reject(err);
        });
    });
  },
  getLocations({ state, commit }) {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: state.Configs.getDistricts,
      })
        .then((resp) => {
          commit("set_locations", resp.data);
          resolve(resp);
        })
        .catch((err) => {
          commit("remove_locations");
          reject(err);
        });
    });
  },
  getEcommerceItems({ state, commit }) {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: state.Configs.getAllCarts,
      })
        .then((resp) => {
          commit("set_carts", resp.data.Items);
          commit("update_coupon", resp.data.Coupon);
          resolve(resp);
        })
        .catch((err) => {
          commit("set_carts", []);
          reject(err);
        });
    });
  },
  getExistingCarts({ commit }) {
    return new Promise((resolve) => {
      commit("get_existing_carts");
      resolve('done');
    });
  },
  updateCards({ commit }, productObj) {
    return new Promise((resolve) => {
      commit("update_carts", productObj);
      resolve(productObj);
    });
  },
  deleteFromCarts({ state, commit }, productObj) {
    return new Promise((resolve, reject) => {
      if (productObj.CartType != "BuyNow") {
        axios({
          method: "post",
          url: state.Configs.removeFromCart,
          data: { ItemId: productObj.ItemId },
        })
          .then((resp) => {
            commit("delete_from_carts", productObj);
            commit("update_coupon", resp.data.Coupon);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        commit("delete_from_carts", productObj);
        resolve(productObj);
      }
    });
  },
  setCartItemsToEmpty({ commit }, cartType) {
    return new Promise((resolve) => {
      commit("empty_carts", cartType);
      resolve(cartType);
    });
  },
  updateCoupon({ commit }, coponObj) {
    return new Promise((resolve) => {
      commit("update_coupon", coponObj);
      resolve(coponObj);
    });
  },
  removeCoupon({ state, commit }, couponObj) {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: state.Configs.removePromoCode,
        data: { Code: couponObj.Code, Type: couponObj.Type },
      })
        .then((resp) => {
          commit("delete_coupon", couponObj);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getUserProfile({ state, commit }) {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: state.Configs.getUserProfile,
      })
        .then((resp) => {
          if (resp.data.DOB) {
            resp.data.DOB = format(new Date(resp.data.DOB), "yyyy-MM-dd");
          }
          commit("update_profile", resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateSelectedCartItems({ state, commit }, selectedCartItemIds) {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: state.Configs.updateCartItems,
        data: selectedCartItemIds,
      })
        .then((resp) => {
          commit("set_carts", resp.data.Items);
          commit("update_coupon", resp.data.Coupon);
          resolve(resp);
        })
        .catch((err) => {
          commit("set_carts", []);
          reject(err);
        });
    });
  }
};

export default STORE_ACTIONS;
