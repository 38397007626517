<template>
  <ul v-if="menus && menus.length">
    <li v-for="menu in menus" :key="menu.Id">
      <router-link
        v-if="menu.Link"
        :to="menu.Link"
        :style="{ color: menu.Color }"
        :class="{ 'router-link-exact-active': selectedMenuId == menu.Id }"
      >
        <img
          :src="$root.generateMediaImageUrl(menu.ImageUrl)"
          alt="menu icon"
        />
        <span v-text="menu.Name"></span>
      </router-link>
    </li>
    <template v-if="promotionalCategories && promotionalCategories.length">
      <li
        v-for="(promotionalCategory, index) in promotionalCategories"
        :key="'p-' + index"
      >
        <router-link
          :to="{
            name: 'ProductSearch',
            params: { slug: slugify(promotionalCategory.Name) },
            query: {
              MenuType: 'm' + promotionalCategory.Id,
              Category: promotionalCategory.Id,
            },
          }"
          :style="{ color: firstMenu.Color || 'white' }"
          :class="{
            'router-link-exact-active':
              selectedMenuId == 'm' + promotionalCategory.Id,
          }"
        >
          <img
            :src="
              $root.generateMediaImageUrl(promotionalCategory.HeaderIconUrl)
            "
            alt="menu icon"
          />
          <span
            v-html="
              promotionalCategory.Name +
              '&nbsp; &nbsp; &nbsp;' +
              getBadge(promotionalCategory.Badge)
            "
          ></span>
        </router-link>
      </li>
    </template>
  </ul>
</template>

<script>
import { SlugifyMixin } from "@/mixins/SlugifyMixin";
export default {
  name: "HeaderMenu",
  mixins: [SlugifyMixin],
  data() {
    return {
      selectedMenuId: 0,
    };
  },
  computed: {
    menus: function () {
      return this.$store.state.itemGroups;
    },
    firstMenu() {
      return this._.first(this.menus);
    },
    promotionalCategories() {
      return this.getFlattenCategories(this.$store.state.allCategories.Data);
    },
  },
  methods: {
    getFlattenCategories(data) {
      const results = [];
      this._.each(data, (item) => {
        this._.each(item.Children, (child) => {
          if (child.ShowOnHeader) {
            results.push({
              Id: child.Id,
              Name: child.Name,
              HeaderIconUrl: child.HeaderIconUrl,
              Badge: child.Badge,
            });
          }
        });
        if (item.ShowOnHeader) {
          results.push({
            Id: item.Id,
            Name: item.Name,
            HeaderIconUrl: item.HeaderIconUrl,
            Badge: item.Badge,
          });
        }
      });
      return results;
    },
    getBadge(txt) {
      if (txt) {
        return "<i>" + txt + "</i>";
      }
      return "";
    },
  },
  mounted() {
    this.selectedMenuId =
      this.$route.query.ItemGroup || this.$route.query.MenuType || 0;
  },
  watch: {
    $route(to) {
      this.selectedMenuId = to.query.ItemGroup || to.query.MenuType || 0;
    },
  },
};
</script>
