<template>
  <div>
    <v-layout
      v-if="$root.windowSize >= 960"
      d-block
      tag="header"
      class="header white--text flex-grow-0"
    >
      <v-layout class="top-header-wrapper">
        <v-container class="py-0">
          <v-layout class="top-header mx-0 wrap">
            <!-- Top header left -->
            <v-flex
              class="top-header-left flex-grow-0 d-flex align-center justify-space-between"
            >
              <!-- require('@/assets/images/logo.png') -->
              <router-link :to="{ name: 'Home' }">
                <v-img
                  class="flex-grow-0"
                  alt="nilam logo"
                  max-width="137"
                  max-height="39"
                  contain
                  :src="
                    $root.generateMediaImageUrl(
                      siteSettings.LogoPath,
                      'logo-bg.png'
                    )
                  "
                ></v-img>
              </router-link>

              <v-flex class="d-flex align-center flex-grow-0">
                <!--header Categories -->
                <all-categories></all-categories>

                <!--header location -->
                <v-layout v-if="false" class="select-box-wrapper flex-grow-0">
                  <location></location>
                </v-layout>
              </v-flex>
            </v-flex>

            <!-- Top header mid -->
            <v-flex class="top-header-mid pa-0">
              <head-search></head-search>
            </v-flex>

            <!-- Top header right -->
            <v-layout justify-end class="top-header-right align-center">
              <!-- <v-flex class="top-header-widgets flex-grow-0">
                <language></language>
              </v-flex>-->

              <v-flex class="top-header-widgets flex-grow-0">
                <router-link :to="{ name: 'WishList' }">
                  <v-flex class="header-widgets-icon">
                    <!-- <v-icon>mdi-cards-heart</v-icon> -->

                    <img :src="require('@/assets/images/svg-icon/hart.svg')" />
                    <v-flex class="count" v-if="isLoggedIn">
                      <span v-text="profileObj.FavoriteItemCount"></span>
                    </v-flex>
                  </v-flex>
                  <span>{{ $t("Favorite") }}</span>
                </router-link>
              </v-flex>

              <v-flex class="top-header-widgets flex-grow-0">
                <router-link
                  :to="{
                    name: 'Checkout',
                    params: { cartType: 'ecommerce' },
                  }"
                >
                  <v-flex class="header-widgets-icon">
                    <v-icon>mdi-cart</v-icon>
                    <!-- <img :src="require('@/assets/images/svg-icon/cart.svg')" /> -->
                    <v-flex class="count" v-if="isLoggedIn">
                      <span v-text="profileObj.CartQuantityCount"></span>
                    </v-flex>
                  </v-flex>
                  <span>{{ $t("My Cart") }}</span>
                </router-link>
              </v-flex>

              <!-- Accounts dropdowns -->
              <v-flex class="top-header-widgets flex-grow-0">
                <v-menu
                  rounded="0"
                  offset-y
                  transition="slide-x-transition"
                  :offset-x="offset"
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-flex v-bind="attrs" v-on="on" class="pointer">
                      <v-flex class="header-widgets-icon">
                        <v-flex class="count" v-if="isLoggedIn">
                          <span
                            v-text="profileObj.UnReadNotificationCount"
                          ></span>
                        </v-flex>
                        <v-avatar
                          size="24"
                          v-if="isLoggedIn && profileObj.ProfileImageUrl"
                        >
                          <v-img
                            width="24"
                            height="24"
                            contain
                            :src="
                              $root.generateMediaImageUrl(
                                profileObj.ProfileImageUrl
                              )
                            "
                            alt="profile image"
                          ></v-img>
                        </v-avatar>
                        <!-- <v-icon v-else>mdi-account</v-icon> -->
                        <img
                          v-else
                          :src="require('@/assets/images/svg-icon/user.svg')"
                        />
                        <v-icon>mdi-chevron-down</v-icon>
                      </v-flex>
                      <span
                        class="text-truncate sign-text"
                        v-if="isLoggedIn"
                        v-text="profileObj.FullName"
                      ></span>
                      <span v-else v-text="$t('Sign In')"></span>
                    </v-flex>
                  </template>
                  <v-list class="login-dropdown" flat dense>
                    <v-list-item>
                      <v-list-item-title>
                        <v-sheet
                          v-if="isLoggedIn"
                          tile
                          class="user-box mb-3 d-flex align-center"
                        >
                          <v-avatar size="75">
                            <router-link :to="{ name: 'MyAccount' }">
                              <v-img
                                width="75"
                                height="75"
                                contain
                                :src="
                                  $root.generateMediaImageUrl(
                                    profileObj.ProfileImageUrl
                                  )
                                "
                                alt="profile image"
                              ></v-img>
                            </router-link>
                          </v-avatar>
                          <v-sheet class="user-title pl-4">
                            <router-link :to="{ name: 'MyAccount' }">
                              <span v-text="$t('Hello')"></span>
                              <h4 v-text="profileObj.FullName"></h4>
                            </router-link>
                            <v-icon size="17" color="gray darken-2"
                              >mdi-phone-in-talk</v-icon
                            >
                            <span
                              class="profile-info"
                              v-text="profileObj.Mobile"
                            ></span>
                            <img
                              v-if="isSeller"
                              class="verified"
                              alt="verified seller"
                              :src="
                                require('@/assets/images/verified-seller.png')
                              "
                            />
                          </v-sheet>
                        </v-sheet>
                        <v-flex
                          class="pb-3 log-btns d-flex justify-space-between"
                          v-if="isLoggedIn"
                        >
                          <v-btn
                            width="137"
                            :to="{ name: 'Logout' }"
                            tile
                            depressed
                            class="primary white--text text-none"
                            >{{ $t("logout") }}</v-btn
                          >
                        </v-flex>
                        <v-flex class="pb-6 px-5" v-else>
                          <div class="pb-3 text-center">
                            <span v-text="$t('Welcome To Nilam')"></span>
                          </div>
                          <v-layout class="flex-wrap justify-space-between">
                            <v-btn
                              width="125"
                              :to="{ name: 'Register' }"
                              tile
                              depressed
                              class="primary white--text text-none"
                              >{{ $t("Sign Up") }}</v-btn
                            >
                            <v-btn
                              width="125"
                              :to="{ name: 'Login' }"
                              tile
                              depressed
                              class="brand white--text text-none"
                              >{{ $t("Sign In") }}</v-btn
                            >
                          </v-layout>
                        </v-flex>
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item-group
                      class="account-menus"
                      color="brand"
                      v-if="isLoggedIn"
                      v-model="selectedItem"
                    >
                      <v-divider></v-divider>
                      <menu-render
                        group="common"
                        :menu-arr="profileMenus.common"
                      ></menu-render>
                      <template v-if="isSeller">
                        <h5
                          class="pt-5 pb-2 px-4 text-uppercase grey--text darken-1 font-weight-regular"
                          v-text="profileMenus.seller.title"
                        ></h5>
                        <v-divider></v-divider>
                        <menu-render
                          group="seller"
                          :menu-arr="profileMenus.seller.menuArr"
                        ></menu-render>
                        <h5
                          class="pt-5 pb-2 px-4 text-uppercase grey--text darken-1 font-weight-regular"
                          v-text="profileMenus.customer.title"
                        ></h5>
                        <v-divider></v-divider>
                        <menu-render
                          group="customer"
                          :menu-arr="profileMenus.customer.menuArr"
                        ></menu-render>
                      </template>
                      <template v-else>
                        <h5
                          class="pt-5 pb-2 px-4 text-uppercase grey--text darken-1 font-weight-regular"
                          v-text="profileMenus.customer.title"
                        ></h5>
                        <v-divider></v-divider>
                        <menu-render
                          group="customer"
                          :menu-arr="profileMenus.customer.menuArr"
                        ></menu-render>

                        <h5
                          class="pt-5 pb-2 px-4 text-uppercase grey--text darken-1 font-weight-regular"
                          v-text="profileMenus.seller.title"
                        ></h5>
                        <v-divider></v-divider>
                        <menu-render
                          group="seller"
                          :menu-arr="profileMenus.seller.menuArr"
                        ></menu-render>
                      </template>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </v-flex>
            </v-layout>
          </v-layout>
        </v-container>
      </v-layout>

      <!-- site menu -->
      <!-- <v-layout class="btm-header-wrapper">
        <site-menu></site-menu>
      </v-layout> -->
    </v-layout>

    <v-flex class="stiky-height"></v-flex>

    <!-- Mobile Header -->
    <v-flex v-if="$root.windowSize < 960">
      <mobile-header></mobile-header>
    </v-flex>
  </div>
</template>

<script>
// import MegaCategory from "@/components/sites/common/SiteMenu";
import HeadSearch from "@/components/sites/common/HeadSearch";
// import SiteMenu from "@/components/sites/common/SiteMenu";
import Location from "@/components/sites/common/Location";
import AllCategories from "@/components/sites/common/AllCategories";
import MobileHeader from "@/components/sites/common/MobileHeader";
import MenuRender from "@/components/sites/common/MenuRender.vue";
//import Language from "@/components/sites/common/Language.vue";

export default {
  name: "SiteHeader",

  components: {
    HeadSearch,
    // SiteMenu,
    Location,
    AllCategories,
    MobileHeader,
    MenuRender,
    //Language,
  },

  data: () => ({
    offset: false,
    selectedCategory: "",
    items: ["foo", "bar", "fizz", "buzz"],
    values: [],
    value: null,
    selectedItem: 0,
    intervalIdForSiteMaintence: true,
  }),
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    carts() {
      return this.$store.state.carts;
    },
    profileObj() {
      return this.$store.state.profileObj;
    },
    siteSettings() {
      return this.$store.state.siteSettings;
    },
    profileMenus() {
      if (this.siteSettings.IsECommerce) {
        return this._.filter(this.$store.state.Configs.profileMenus, {
          isEcommerce: this.siteSettings.IsECommerce,
        });
      } else {
        return this.$store.state.Configs.profileMenus;
      }
    },
    isSeller() {
      if (this.$auth && this.$auth.user) {
        return this.$auth.user.IsVerified;
      }
      return false;
    },
  },
  created() {
    this.getProfileObj(this.isLoggedIn);
    this.$auth.setValue(this.isLoggedIn);
    this.getConfigs();
    this.startAndStopSignalR(this.isLoggedIn);
  },
  methods: {
    intervalCallForMaintenenceMode() {
      if (!this.intervalIdForSiteMaintence) {
        this.intervalIdForSiteMaintence = window.setInterval(() => {
          this.$store.dispatch("getToken").then(() => {
            window.clearInterval(this.intervalIdForSiteMaintence);
            this.intervalIdForSiteMaintence = true;
            // this.$router
            //   .push({ name: "Home" })
            //   .catch((err) => console.log(err));
          });
        }, 60000);
      }
    },
    startAndStopSignalR(isLoggedIn) {
      if (isLoggedIn) {
        this.stopSignalR().then(() => {
          this.startSignalR(this.$auth.user.UserId);
        });
      } else {
        this.stopSignalR().then(() => {
          this.startSignalR();
        });
      }
    },
    getProfileObj(IsLoggedIn) {
      if (IsLoggedIn) {
        this.$store.dispatch("getUserProfile");
      } else {
        this.$store.state.profileObj = {
          FavoriteItemCount: 0,
          UnReadNotificationCount: 0,
          CartQuantityCount: 0,
        };
      }
    },

    getConfigs() {
      this.$store
        .dispatch("getSiteSettings")
        .then((res) => {
          this.$store.dispatch("getItemGroups");
          //this.$store.dispatch("getLocations");

          let {
            MetaKeywords,
            MetaDescription,
            MetaAuthor,
            SiteUrl,
            SiteTitle,
            SocialLogoPath,
          } = res.data;
          this.$store.commit("update_meta", {
            MetaTitle: SiteTitle,
            MetaKeywords: MetaKeywords || "",
            MetaDescription: MetaDescription || "",
            MetaAuthor: MetaAuthor || "",
            SiteUrl: SiteUrl || "",
            SocialLogoPath: SocialLogoPath || "",
          });
        })
        .catch(() => {
          if (localStorage.getItem("token")) {
            this.intervalIdForSiteMaintence = false;

            this.$store.dispatch("logout").then(() => {
              this.$store.dispatch("getToken");
              // .then(() => {
              //   this.$router
              //     .push({ name: "Home" })
              //     .catch((err) => console.log(err));
              // });
            });

            this.intervalCallForMaintenenceMode();
          }
        });
    },
  },

  mounted() {
    this.$notificationHub.$on("notification", () => {
      this.$store.commit("update_notification_count", {
        count: 1,
      });
    });
  },
  beforeDestroy() {
    this.$notificationHub.$off("notification");
  },
  watch: {
    isLoggedIn(newVal) {
      this.getProfileObj(newVal);
      this.$auth.setValue(newVal);
      this.startAndStopSignalR(newVal);
    },
  },
};
</script>
<style lang="scss">
@import "@/sass/header.scss";
</style>
