import store from "@/store";

const Auth = {
  install(Vue) {
    Vue.prototype.$auth = Vue.observable({
      user: null,
      setValue(val) {
        if (val) {
          this.user = JSON.parse(store.state.auth);
        } else {
          this.user = null;
        }
      },
    });
  },
};
export default Auth;
