import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      options: {
        customProperties: true,
      },
      light: {
        primary: "#353a44", // header-bg
        secondary: "#30353d", // footer-bg
        header_footer: "#2c313c",
        brand: "#ff9900", // logo-color
        site_bg: "#f1f3f6", // site-bg
        silver_tree: "#68bda1",
        light: "#fff", // site-bg
        success: "#4caf50",
        info: "#2196f3",
        warning: "#fb8c00",
        danger: "#ff5252",
        grey: "#828282",
      },
    },
  },
});
