<template>
  <v-main class="home-bg">
    <v-container v-if="loading">
      <v-skeleton-loader
        class="mx-auto"
        min-height="700"
        v-bind="{ boilerplate: true, elevation: 2 }"
        type="image,list-item-three-line,card-heading"
      ></v-skeleton-loader>
    </v-container>
    <template v-else>
      <div class="home-block-wrapper d-flex flex-wrap justify-center">
        <home-block-sections
          v-for="(blocks, index) in blocksArr"
          :key="index"
          :blocks-obj="blocks"
        ></home-block-sections>
      </div>
    </template>
  </v-main>
</template>

<script>
import HomeBlockSections from "@/components/sites/pages/HomeBlockSections.vue";
export default {
  name: "Home",
  data() {
    return {
      loading: false,
      blocksArr: [],
    };
  },
  computed: {
    siteSettings() {
      return this.$store.state.siteSettings;
    },
  },
  components: {
    HomeBlockSections,
  },
  created() {
    this.getAllBlockApis();
  },
  methods: {
    getAllBlockApis() {
      if (!Object.keys(this.siteSettings).length || this.loading) {
        return;
      }

      this.loading = true;

      this.$http
        .post(this.$store.state.Configs.getAllBlockApis)
        .then((result) => {
          this.blocksArr = result.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },
  watch: {
    siteSettings(newVal) {
      if (Object.keys(newVal).length) {
        this.$store.commit("update_meta", {
          PageTitle: newVal.MetaTitle || newVal.SiteTitle,
        });

        this.getAllBlockApis();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home-bg {
  background-color: #f1f3f6;
}
</style>
