<template>
  <v-layout
    wrap
    class="home-product-sections"
    tag="section"
    :class="[gridClass, bannerType(blocksObj.ApiLink)]"
  >
    <v-flex tag="span" class="section-bg" :class="bgClass">
      <!-- header part start ===-->
      <v-layout
        class="home-sections-title justify-space-between flex-wrap"
        v-if="
          blocksObj.IsTimerShow || blocksObj.IsNameShow || blocksObj.DetailsLink
        "
      >
        <h2 v-if="blocksObj.IsNameShow" v-text="blocksObj.Name"></h2>

        <v-btn
          v-if="blocksObj.DetailsLink"
          height="30"
          width="90"
          outlined
          rounded
          text
          color="primary"
          class="text-none"
          :to="blocksObj.DetailsLink"
          >{{ $t("Show All") }}</v-btn
        >
        <!-- Section timer -->
        <v-flex
          v-if="blocksObj.IsTimerShow"
          class="flex-grow-0 flash-sale-time"
        >
          <v-sheet class="auction-time-holder text-center">
            <countdown
              v-if="blocksObj.EndAt"
              :time="new Date(blocksObj.EndAt).getTime() - new Date().getTime()"
            >
              <template slot-scope="props">
                <span class="time-title">
                  <span class="d-none d-sm-inline">On Sale Now -</span>Ending in
                </span>

                <span class="time-value">{{ props.days }}d</span>
                <span class="time-value">{{ props.hours }}h</span>
                <span class="time-value">{{ props.minutes }}m</span>
                <span class="time-value">{{ props.seconds }}s</span>
              </template>
            </countdown>
            <span v-else>Ended</span>
          </v-sheet>
        </v-flex>
        <!-- Section timer -->
      </v-layout>
      <!-- header part end ===-->

      <home-banner
        v-if="blocksObj.SectionType == 'Banners'"
        :url="blocksObj.ApiLink"
      ></home-banner>

      <hot-buttons
        v-if="blocksObj.SectionType == 'HotButtons'"
        :url="blocksObj.ApiLink"
      ></hot-buttons>

      <highlights
        v-else-if="blocksObj.SectionType == 'Highlights'"
        :url="blocksObj.ApiLink"
      ></highlights>

      <categories
        v-else-if="blocksObj.SectionType == 'Categories'"
        :url="blocksObj.ApiLink"
        :grid="blocksObj.Grid"
      ></categories>

      <brands
        v-else-if="blocksObj.SectionType == 'Brands'"
        :url="blocksObj.ApiLink"
      ></brands>

      <product-section
        v-else-if="blocksObj.SectionType == 'Products'"
        :url="blocksObj.ApiLink"
        :grid="blocksObj.Grid"
      ></product-section>
    </v-flex>
  </v-layout>
</template>
<script>
import Highlights from "@/components/sites/pages/home/Highlights.vue";
import Categories from "@/components/sites/pages/home/Categories.vue";
import Brands from "@/components/sites/pages/home/Brands.vue";
import HotButtons from "@/components/sites/pages/HotButtons.vue";
import HomeBanner from "@/components/sites/pages/HomeBanner.vue";
import ProductSection from "@/components/sites/pages/home/ProductSection.vue";
export default {
  name: "HomeBlockSections",
  components: {
    HotButtons,
    Highlights,
    Categories,
    Brands,
    HomeBanner,
    ProductSection,
  },
  props: {
    blocksObj: Object,
  },
  computed: {
    bgClass() {
      if (
        this.blocksObj.SectionType == "HotButtons" ||
        this.blocksObj.SectionType == "Banners"
      ) {
        return "no-bg";
      }
      return "";
    },
    gridClass() {
      var grid = "grid-" + this.blocksObj.Grid;
      if (this.blocksObj.Grid) {
        grid += " max-width";
      }
      return grid;
    },
  },
  methods: {
    bannerType(url) {
      if (url.includes("banner")) {
        let type = url.split("/").at(-1).toLowerCase();
        if (isNaN(type)) {
          return "banner " + type;
        } else {
          return "banner single";
        }
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/app/index.scss";
.flash-sale-time {
  .auction-time-holder {
    padding: rem-calc(5px 10px);
    background-color: #f1f3f6;
    @include border-radius(10px);
    .time-title {
      @include font(false, 16px, 26px, false);
    }
    .time-value {
      width: 35px;
      margin: rem-calc(0 5px);
      text-align: center;
      display: inline-block;
      background-color: map-get($colors, brand);
      @include font(light, 14px, 26px, medium);
    }
  }
}

.section-bg {
  padding: rem-calc(10px 20px);
  background-color: #fff;
  border-radius: 10px;
  &.no-bg {
    padding: 0;
    background-color: transparent;
  }
}
.home-block-wrapper::before,
.home-block-wrapper::after {
  flex-basis: 0;
  order: 1;
}

.home-product-sections {
  width: 100%;
  padding: rem-calc(0 6px 18px);
  &.grid-0 {
    padding-left: 0;
    padding-right: 0;
  }

  &.grid-6 {
    max-width: (1602px / 2);
  }
  &.grid-4 {
    max-width: (1602px / 3);
  }
  &.bottompage {
    padding-bottom: 0;
  }
  .home-sections-title {
    position: relative;
    .flash-sale-time {
      @include center(395px, false);
    }
    h2 {
      position: relative;
      padding-bottom: rem-calc(4px);
      margin-bottom: 5px;

      @include font(primary, 22px, 33px, medium);

      &:before {
        @include sudo(50px, 2px, left, 0, bottom, 0, brand);
      }
    }
    .v-btn.v-btn--outlined.v-btn--text {
      background-color: map-get($colors, light);
      border-color: map-get($colors, brand);
    }
  }
}
.grid-12:before,
.grid-12:after {
  flex-basis: 0;
  order: 1;
}

//for odd number

//for even number

@include media(1639px) {
  .section-bg {
    padding: rem-calc(10px 12px);
  }
  .home-product-sections {
    &.grid-6 {
      max-width: (95% / 2);
    }
    &.grid-4 {
      max-width: (95% / 3);
    }
  }
}
@include media(md) {
  .section-bg {
    padding: rem-calc(10px 12px);
  }
  .home-product-sections {
    &.grid-6 {
      max-width: (95%);
      &::v-deep .product-items {
        max-width: 33.33%;
      }
    }
    &.grid-4 {
      max-width: (95%);
    }
  }
}
@include media(md) {
  .home-product-sections {
    &.grid-6 {
      &::v-deep .product-items {
        max-width: 50%;
      }
    }
    .home-sections-title {
      position: relative;
      .flash-sale-time {
        padding: rem-calc(10px 0 8px);
        position: static;
        transform: none;
        width: 100%;
        display: flex;
      }
    }
  }
}
@include media(sm) {
  .home-product-sections {
    &.grid-6 {
      &::v-deep .product-items {
        max-width: 50%;
      }
    }
  }
}
@include media(479px) {
  .home-product-sections {
    .home-sections-title {
      h2 {
        font-size: 18px !important;
      }
    }
  }
}
</style>
