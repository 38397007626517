<template>
  <v-layout :class="bannerType != 'slider' ? 'common ' + bannerType : bannerType" tag="section">
    <v-img
      width="100%"
      :src="
        getImageUrl(
          $root.windowSize,
          bannerObj.ImageUrl,
          bannerObj.MobileImageUrl
        )
      "
      :lazy-src="require('@/assets/images/default-slider.jpg')"
      :height="imgHeight"
      alt="Banner Image"
      title="Banner Image"
      class="pointer"
      @click="goToLink(bannerObj.Link)"
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular indeterminate color="grey lighten-5" aria-label="loading status"></v-progress-circular>
        </v-row>
      </template>

      <v-flex class="banner-caption d-flex fill-height align-center">
        <v-container class="py-0">
          <v-btn
            v-if="bannerType == 'slider' && bannerObj.HeadTitle"
            height="auto"
            color="primary"
            :href="bannerObj.Link"
            tile
            depressed
            class="white--text text-none banner-top-btn"
          >
            <span class="head-title" v-text="bannerObj.HeadTitle"></span>
          </v-btn>
          <h2
            v-else-if="bannerType != 'slider' && bannerObj.HeadTitle"
            :class="bannerObj.ColorCode + '--text head-title'"
            v-text="bannerObj.HeadTitle"
          ></h2>
          <h2
            v-if="bannerType == 'slider' && bannerObj.Title"
            class="text-uppercase banner-title"
            v-html="showText(bannerObj.Title, titleBreakCount)"
            :class="bannerObj.ColorCode + '--text'"
          ></h2>
          <p
            :class="bannerObj.ColorCode + '--text banner-summery'"
            v-html="showText(bannerObj.Summary, summeryBreakCount)"
            v-if="bannerObj.Summary"
          ></p>
          <v-flex class="banner-btns">
            <v-btn
              height="auto"
              color="brand"
              :href="bannerObj.Link"
              v-if="bannerObj.ButtonText"
              tile
              depressed
              class="white--text banner-btn"
            >
              <span class="btn-text" v-text="bannerObj.ButtonText"></span>
              <v-icon class="white--text pl-2">mdi-trending-neutral</v-icon>
            </v-btn>
          </v-flex>
        </v-container>
      </v-flex>
    </v-img>
  </v-layout>
</template>

<script>
export default {
  name: "SingleBanner",
  data() {
    return {
      titleBreakCount: 3,
      summeryBreakCount: 8,
    };
  },
  props: {
    bannerType: String,
    bannerObj: Object,
    height: Number,
  },
  created() {
    if (this.bannerType != "slider") {
      this.titleBreakCount = 5;
      this.summeryBreakCount = 12;
    }
  },
  computed: {
    imgHeight() {
      if (this.height) {
        return this.height;
      }
      return "auto";
    },
  },
  methods: {
    goToLink(link) {
      if (!link) return;

      if (this.bannerType == "bottompage" || this.bannerType == "middlepage") {
        return;
      }

      if (/^https?:\/\//i.test(link)) {
        window.open(link, "_blank");
      } else {
        return this.$router.push(link);
      }
    },
    getImageUrl(screenSize, WebUrl, MobileUrl) {
      if (!MobileUrl) {
        MobileUrl = WebUrl;
      }

      if (screenSize < 920) {
        return this.$asset_root + MobileUrl;
      } else {
        return this.$asset_root + WebUrl;
      }
    },
    showText(str, count) {
      let splited = str.split(" ");
      if (splited.length > count) {
        let arr = this._.chunk(splited, count);
        let text = "";
        arr.forEach((element, idx, array) => {
          if (idx === array.length - 1) {
            text += element.join(" ");
          } else {
            text += element.join(" ") + "<br>";
          }
        });
        return text;
      }
      return str;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/single_banner.scss";
</style>
