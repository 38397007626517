import { hubUrl } from "../routeConfig";

import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
const NotificationHub = {
  install(Vue) {
    this.hubUrl = hubUrl;

    let connection = null;
    let startedPromise = null;
    let manuallyClosed = false;

    // use new Vue instance as an event bus
    const notificationHub = new Vue();
    // every component will use this.$notificationHub to access the event bus
    Vue.prototype.$notificationHub = notificationHub;
    // Forward server side SignalR events through $notificationHub, where components will listen to them

    Vue.prototype.startSignalR = (userId) => {
      if (userId) {
        this.hubUrl = this.hubUrl + "?userId=" + userId;
      } else {
        this.hubUrl = this.hubUrl.split("?")[0];
      }

      connection = new HubConnectionBuilder()
        .withUrl(this.hubUrl)
        .configureLogging(LogLevel.Information)
        .build();

      connection.on("broadcast", (type, obj) => {
        console.log("connection-broadcast-broadcasted");
        notificationHub.$emit("broadcast", { type, obj });
      });

      connection.on("sendToUser", (type, obj) => {
        console.log("connection-sendToUser-broadcasted");
        notificationHub.$emit("notification", { type, obj });
      });

      connection.on("bargain", (type, obj) => {
        console.log("connection-bargain-broadcasted");
        notificationHub.$emit("bargain", { type, obj });
      });

      function start() {
        startedPromise = connection.start().catch((err) => {
          console.error("Failed to connect with hub", err);
          return new Promise((resolve, reject) =>
            setTimeout(() => start().then(resolve).catch(reject), 5000)
          );
        });
        return startedPromise;
      }

      connection.onclose(() => {
        if (!manuallyClosed) start();
      });

      // Start everything
      manuallyClosed = false;
      start();
    };

    Vue.prototype.stopSignalR = () => {
      if (!startedPromise) return new Promise((resolve) => resolve());

      manuallyClosed = true;
      return startedPromise
        .then(() => connection.stop())
        .then(() => {
          startedPromise = null;
        });
    };
  },
};
export default NotificationHub;
