<template>
  <v-app
    v-if="hasClientToken"
    :class="headerClassObj"
    v-scroll="onScroll"
    v-resize="onResize"
  >
    <SiteHeader v-if="requireHaderFooter" ref="stickyheader" />
    <v-snackbar
      v-if="Object.keys(flashObj).length"
      v-model="snackBar"
      :color="flashObj.type.toLowerCase()"
      right
      bottom
      rounded
      timeout="4000"
    >
      <span v-text="flashObj.message"></span>

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackBar = false"> Close </v-btn>
      </template>
    </v-snackbar>

    <router-view></router-view>
    <SiteFooter v-if="requireHaderFooter" />
  </v-app>
  <v-app v-else>
    <v-flex v-if="tokenStatus == 'loading'" class="h-100 w-100 d-flex">
      <v-progress-circular
        class="ma-auto"
        :size="70"
        :width="7"
        color="purple"
        indeterminate
        aria-label="token loading status"
      ></v-progress-circular>
    </v-flex>
    <!-- Site Maintenance Page -->
    <maintenance v-else></maintenance>
  </v-app>
</template>

<script>
import SiteHeader from "./components/sites/common/SiteHeader";
import SiteFooter from "./components/sites/common/SiteFooter";
import Maintenance from "@/components/sites/common/Maintenance";

export default {
  name: "App",
  components: {
    SiteHeader,
    SiteFooter,
    Maintenance,
  },
  data() {
    return {
      windowSize: 0,
      static_header: false,
      isModalOpen: false,
      title: process.env.VUE_APP_TITLE,
      top: 0,
      oldTop: 0,
      requireHaderFooter: false,
    };
  },
  metaInfo() {
    return {
      title: this.title,
      titleTemplate: this.getMetaTitle(), //"%s | home"
      meta: [
        {
          vmid: "author",
          name: "author",
          content: this.getMetaAuthor(),
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: this.getMetaKeywords(),
        },
        {
          vmid: "description",
          name: "description",
          content: this.getMetaDescription(),
        },

        {
          vmid: "og:url",
          property: "og:url",
          content: location.origin + this.$route.fullPath,
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: this.getMetaTitle(),
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.getMetaDescription(),
        },
        {
          vmid: "og:image",
          property: "og:image",
          content: this.socialShareImage(),
        },
        {
          vmid: "og:image:secure",
          property: "og:image:secure",
          content: this.socialShareImage(),
        },
        {
          vmid: "og:site_name",
          property: "og:site_name",
          content: this.title,
        },

        {
          vmid: "twitter:card",
          property: "twitter:card",
          content: "summary_large_image",
        },
        {
          vmid: "twitter:site",
          property: "twitter:site",
          content: this.title,
        },
        {
          vmid: "twitter:title",
          property: "twitter:title",
          content: this.getMetaTitle(),
        },
        {
          vmid: "twitter:image",
          property: "twitter:image",
          content: this.socialShareImage(),
        },
        {
          vmid: "twitter:description",
          property: "twitter:description",
          content: this.getMetaDescription(),
        },
        {
          vmid: "twitter:url",
          property: "twitter:url",
          content: "https://twitter.com/nilam",
        },
      ],
    };
  },
  computed: {
    tokenStatus() {
      return this.$store.getters.tokenStatus;
    },
    hasClientToken() {
      return this.$store.getters.hasClientToken;
    },
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    flashObj() {
      return Object.assign({}, this.$store.state.flash);
    },
    snackBar: {
      // getter
      get: function () {
        return Object.keys(this.flashObj).length;
      },
      // setter
      set: function (newValue) {
        if (!newValue) {
          this.$store.commit("set_flash", {});
        }
      },
    },
    siteSettings() {
      return this.$store.state.siteSettings;
    },
    mediaConfiguration() {
      return this._.map(
        this._.filter(this.siteSettings.MediaConfiguration, {
          IsDefault: false,
        }),
        "Category"
      );
    },
    currencyIcon() {
      return this.siteSettings.CurrencyIcon;
    },
    dimentions() {
      return this.$store.state.Configs.dimentions;
    },
    countDown() {
      return this.$store.state.Configs.countDown;
    },
    dayNames() {
      return this.$store.state.Configs.dayNames;
    },
    monthNames() {
      return this.$store.state.Configs.monthNames;
    },
    metaObj() {
      return this.$store.state.metaObj;
    },
    headerClassObj() {
      return {
        stiky_header: this.static_header,
        not_stiky_header: !this.static_header,
      };
    },
  },
  methods: {
    onResize() {
      this.windowSize = window.innerWidth;
    },
    getMetaTitle() {
      var title = this.title;
      if (this.metaObj.PageTitle) {
        title = this.metaObj.PageTitle + " | " + title;
      } else {
        title = "Online auction, wholesale & bargain in Bangladesh | " + title;
      }
      return title;
    },
    getMetaAuthor() {
      return this.metaObj.MetaAuthor || "Nilam.xyz";
    },
    getMetaKeywords() {
      return (
        this.metaObj.MetaKeywords ||
        "auction,nilam,bargain,sales,used,ecommerce,ecommerce in bangladesh,bangladesh,online shopping,free,coupon,offer,campaign,free offer,best deal,deal,best,grocery,gadget,original,trusted,global,mnc,multinational,profit,seller,best seller,entreprenuer,benefit,shopping,original,best item,COD,instant delivery,best delivery,cash on delivery"
      );
    },
    getMetaDescription() {
      return (
        this.metaObj.MetaDescription ||
        "Top online shopping in Bangladesh with Bazaar Retail, Wholesale, Auction, and Bargain, all in one concept for both buy and sale from million products in Bangladesh with cash on delivery (COD)"
      );
    },
    socialShareImage() {
      if (this.metaObj.SocialLogoPath) {
        return this.generateMediaImageUrl(this.metaObj.SocialLogoPath);
      }
      return this.$asset_root + "assets/images/logo.png";
    },
    showAmount(amount) {
      if (!amount) return this.currencyIcon + 0;
      return this.currencyIcon + amount.toLocaleString();
    },
    getTime(productObj) {
      let timeCount = 0;

      if (productObj.AuctionStatus == "Published") {
        timeCount =
          new Date(productObj.StartTime).getTime() - new Date().getTime();
      } else if (productObj.AuctionStatus == "Bidding") {
        timeCount =
          new Date(productObj.EndTime).getTime() - new Date().getTime();
      }

      if (timeCount && timeCount > 0) {
        return timeCount;
      }
      return 0;
    },
    getCountdownTime(maxTime) {
      let timeCount = new Date(maxTime).getTime() - new Date().getTime();
      if (timeCount && timeCount > 0) {
        return timeCount;
      }
      return 0;
    },
    formateServerDateTime(time, withTime = true) {
      let t = new Date(time);
      var format = "AM";
      var hour = t.getHours();
      var min = t.getMinutes();
      if (hour > 11) {
        format = "PM";
      }
      if (hour > 12) {
        hour = hour - 12;
      }
      if (hour == 0) {
        hour = 12;
      }
      if (min < 10) {
        min = "0" + min;
      }
      var month = this.monthNames[t.getMonth()];
      var day = t.getDate();
      if (day.toString().length == 1) {
        day = "0" + day;
      }
      var year = t.getFullYear();
      if (withTime) {
        return (
          day + "-" + month + "-" + year + " " + hour + ":" + min + " " + format
        );
      } else {
        return day + "-" + month + "-" + year;
      }
    },
    generateImageUrl(product, type) {
      if (!type) {
        return (
          this.$asset_root + product.ImageRootUrl + "/" + product.ImageName
        );
      } else {
        return (
          this.$asset_root +
          product.ImageRootUrl +
          "/" +
          type +
          "/" +
          product.ImageName
        );
      }
    },
    generateSlideImageUrl(image, type) {
      if (!type) {
        return this.$asset_root + image.LocalPath + "/" + image.UUID;
      } else {
        return (
          this.$asset_root + image.LocalPath + "/" + type + "/" + image.UUID
        );
      }
    },
    generateMediaImageUrl(url, noImageName) {
      if (url) {
        return this.$asset_root + url;
      } else {
        if (!noImageName) noImageName = "no-img.jpg";
        return require("@/assets/images/" + noImageName);
      }
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      this.top = window.pageYOffset || e.target.scrollTop || 0;
      if (this.oldTop < this.top) {
        if (this.top > this.$refs.stickyheader?.$el?.clientHeight) {
          this.static_header = true;
        } else {
          this.static_header = false;
        }
      } else if (this.oldTop > this.top) {
        if (this.top == 0) {
          this.static_header = false;
        } else {
          this.static_header = true;
        }
      }
      this.oldTop = this.top;
    },
    // For count down timer
    transform(props) {
      Object.entries(props).forEach(([key, value]) => {
        // Adds leading zero
        const digits = value < 10 ? `0${value}` : value;
        // uses singular form when the value is less than 2
        const word = value < 2 ? key.replace(/s$/, "") : key;
        props[key] = `<b>${digits}</b> ${word}`;
      });
      return props;
    },
    transformShorter(props) {
      Object.entries(props).forEach(([key, value]) => {
        // Adds leading zero
        const digits = value < 10 ? `0${value}` : value;
        // uses singular form when the value is less than 2
        const word = key.substring(0, 1);
        props[key] = `${digits}${word}`;
      });
      return props;
    },
    checkAuth(path, cartableObj) {
      if (!this.isLoggedIn) {
        var loginRedirect = path || this.$route.fullPath;
        localStorage.setItem("loginRedirect", loginRedirect);

        if (cartableObj) {
          localStorage.setItem(
            "local-cartable-obj",
            JSON.stringify(cartableObj)
          );
        }

        this.$router.push({ name: "Login" });
        return true;
      }
      return false;
    },
    collect(array, Id) {
      var object;
      array.some(function f(a) {
        if (a.Id == Id) {
          object = a.Parents;
          return true;
        }
        if (Array.isArray(a.Children)) {
          return a.Children.some(f);
        }
      });
      return object;
    },
    syncHttp(url, obj) {
      return new Promise((resolve, reject) => {
        this.$http
          .post(url, { ...obj })
          .then((result) => {
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    replaceAt(string, index, replacement) {
      return (
        string.substr(0, index) +
        replacement +
        string.substr(index + replacement.length)
      );
    },
  },
  created() {
    this.requireHaderFooter = this.$router.currentRoute.meta.requireHaderFooter;

    this.$http.interceptors.response.use(undefined, (err) => {
      return new Promise(() => {
        if (
          err.response.status === 401 &&
          err.response.config &&
          !err.response.config.__isRetryRequest
        ) {
          if (localStorage.getItem("token")) {
            this.$store.dispatch("logout").then(() => {
              this.$store.dispatch("getToken");
              // .then(() => {
              //   this.$router
              //     .push({ name: "Home" })
              //     .catch((err) => console.log(err));
              // });
            });
          }
        }
        throw err;
      });
    });
  },
  mounted() {
    this.onResize();
    if (this.tokenStatus == "error") {
      this.$router.push({ name: "clientError" });
    }
  },
  watch: {
    $route(to) {
      this.requireHaderFooter = to.meta.requireHaderFooter;
    },
  },
};
</script>
