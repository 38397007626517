<template>
  <v-layout class="home-group">
    <v-flex v-if="loading">
      <v-skeleton-loader class="mx-auto" type="table-row"></v-skeleton-loader>
    </v-flex>
    <template v-else>
      <v-layout wrap class="group-item-wrap">
        <v-flex v-for="(data, index) in dataArr" :key="index" class="flex-grow-0 group-item">
          <router-link :to="data.Link" class="d-flex group-item-bg">
            <v-flex class="flex-grow-0 group-item-icon rounded-circle">
              <v-img
                contain
                width="23"
                height="23"
                :alt="data.Title"
                :src="$root.generateMediaImageUrl(data.IconPath)"
              ></v-img>
            </v-flex>

            <span v-text="data.Title"></span>
          </router-link>
        </v-flex>
      </v-layout>
    </template>
  </v-layout>
</template>

<script>
import { HomeBlockMixins } from "@/mixins/HomeBlockMixins";
export default {
  name: "HotButtons",
  mixins: [HomeBlockMixins],
};
</script>

<style lang="scss" scoped>
@import "@/sass/home_group.scss";
</style>
