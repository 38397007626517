export const clientInfo = {
  grant_type: "client_credentials",
  client_id: process.env.VUE_APP_CLIENT_ID,
  client_secret: process.env.VUE_APP_CLIENT_SECRET,
  offset: new Date().getTimezoneOffset(),
};

export const langs = [
  {
    name: "en-US",
    title: "EN",
    image: "us.svg",
  },
  {
    name: "bn-BD",
    title: "BN",
    image: "bd.svg",
  },
];

export const dimentions = {
  thumb: "Thumb",
  grid: "Grid",
  original: "Original",
  raw: "",
};

// resendOtpTime in seconds
export const resendOtpTime = 30;

export const countDown = {
  Text: {
    Published: "Start After",
    Bidding: "Time Left",
  },
  Icon: {
    Published: "mdi-clock-time-five-outline",
    Bidding: "mdi-clock-time-five-outline",
  },
  Color: {
    Published: "",
    Bidding: "brand--text",
  },
};

export const orderCancelationReasons = [
  "Size Issue",
  "Color issue",
  "Quality",
  "Order confirmation time over",
  "Delivery time over",
];

export const dayNames = [
  "Sun",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const profileMenus = {
  common: [
    {
      text: "Dashboard",
      icon: "mdi-home",
      link: "Dashboard",
      isEcommerce: true,
    },
    {
      text: "My Account",
      icon: "mdi-account-edit",
      link: "MyAccount",
      isEcommerce: true,
    },
    {
      text: "Address",
      icon: "mdi-map-marker",
      link: "Address",
      isEcommerce: true,
    },
    {
      text: "Notification",
      icon: "mdi-bell",
      link: "Notification",
      count: "UnReadNotificationCount",
      isEcommerce: true,
    },
  ],
  seller: {
    title: "As a Seller",
    menuArr: [
      {
        text: "Received Orders",
        icon: "mdi-badge-account-outline",
        link: "ReceivedOrder",
        isEcommerce: true,
      },
      {
        text: "Received Bargains",
        icon: "mdi-badge-account-outline",
        link: "ReceivedBargain",
        isEcommerce: true,
      },
      {
        text: "Add Product",
        icon: "mdi-plus-thick",
        link: "postProductStep1",
        isEcommerce: false,
      },
      {
        text: "My Products",
        icon: "mdi-package-variant",
        link: "MyProducts",
        isEcommerce: false,
      },
      // {
      //   text: "My Brands",
      //   icon: "mdi-package-variant",
      //   link: "MyBrands",
      //   isEcommerce: true,
      //   sellerOnly: true,
      // },
      // {
      //   text: "Campaign",
      //   icon: "mdi-bullhorn-outline",
      //   link: "Campaign",
      //   isEcommerce: false,
      //   sellerOnly: true,
      // },
      {
        text: "Balance Summary",
        icon: "mdi-note-text-outline",
        link: "PostingSummary",
        isEcommerce: true,
      },
    ],
  },
  customer: {
    title: "As a Customer",
    menuArr: [
      {
        text: "Favorite Product",
        icon: "mdi-cards-heart",
        link: "WishList",
        count: "FavoriteItemCount",
        isEcommerce: true,
      },
      {
        text: "Cart Product",
        icon: "mdi-cart",
        link: "Checkout",
        count: "CartQuantityCount",
        params: { cartType: "ecommerce" },
        isEcommerce: true,
      },
      {
        text: "My Orders",
        icon: "mdi-badge-account-outline",
        link: "MyOrder",
        isEcommerce: true,
      },
      {
        text: "Participated Nilam",
        icon: "mdi-hammer-screwdriver",
        link: "MyAuction",
        isEcommerce: false,
      },
      {
        text: "My Bargains",
        icon: "mdi-crown",
        link: "MyBargain",
        isEcommerce: false,
      },
    ],
  },
};

export const socials = [
  {
    name: "mdi-facebook",
    class: "facebook",
  },
  {
    name: "mdi-pinterest",
    class: "pinterest",
  },
  {
    name: "mdi-linkedin",
    class: "linkedin",
  },
  {
    name: "mdi-twitter",
    class: "twitter",
  },
];

export const orderProgress = [
  "Pending",
  "Confirmed",
  "Processing",
  "Delivered",
];

export const priceNote = {
  startingPrice: {
    title: "Starting Price",
    text: `The basic price when nothing extra has been
                        added / the lowest possible price for a
                        particular type of thing.`,
    SaleType: "Auction",
  },
  salesPriceBargain: {
    title: "Sales Price",
    text: `The price at which you want to sell your product`,
    SaleType: "Bargain",
  },
  salesPriceGallery: {
    title: "Sales Price",
    text: `The price at which you want to sell your product`,
    SaleType: "Gallery",
  },
  buyNowPrice: {
    title: "Buy Now Price",
    text: `You can set a fixed price for buyers in this
                      field or if you don’t want this feature you
                      can set buy now price zero.`,
    SaleType: "Auction",
  },
  reservePrice: {
    title: "Reserve Price",
    text: `The price stipulated as the lowest acceptable
                      by the seller for an item sold at auction. If
                      the last bidding price is lower than the
                      reserve price then the auction might get
                      cancelled.`,
    SaleType: "Auction",
  },
  lastPrice: {
    title: "Last Price",
    text: `The least price at which the product you want to sell`,
    SaleType: "Bargain",
  },
};

export const charCodeListsForBot = [
  48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 2534, 2535, 2536, 2537, 2538, 2539,
  2540, 2541, 2542, 2543,
];

export const skipMaxNumberInput = 2;
