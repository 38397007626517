<template>
  <!-- Product section -->
  <v-flex class="product-box">
    <v-hover>
      <template v-slot="{ hover }">
        <v-card elevation="0" class="product-card">
          <v-flex class="product-image-box">
            <v-img
              :title="product.ItemName"
              :src="$root.generateImageUrl(product, productImgForScreeSize)"
              :lazy-src="require('@/assets/images/no-img.jpg')"
              :alt="product.ItemName"
              aspect-ratio="1"
              :aria-label="product.ItemName"
            >
              <router-link
                :to="{
                  name: 'ProductDetails',
                  params: { id: product.Id, slug: product.Slug },
                }"
                role="link"
                aria-label="click for details"
                class="fill-height d-block"
              ></router-link>

              <!-- Product top labels -->
              <v-flex class="flex-grow-0 product-top-left">
                <div class="condition-wrap" v-if="product.StockQuantity">
                  <span
                    class="condition type"
                    v-if="product.Condition != 'New'"
                  >
                    {{ product.Condition }}
                    <span v-if="product.StockType == 'Lot'">
                      ({{ product.StockType }})
                    </span>
                  </span>

                  <span
                    class="condition type"
                    v-else-if="
                      product.Condition == 'New' && product.StockType == 'Lot'
                    "
                  >
                    {{ product.StockType }}
                  </span>
                  <span
                    v-if="product.DiscountTag"
                    class="condition discount"
                    v-text="product.DiscountTag"
                  ></span>
                  <span class="condition emi" v-if="product.IsEmi">EMI</span>
                </div>
                <v-img
                  v-else
                  class="sold"
                  :src="require('@/assets/images/sold.png')"
                  alt="Products"
                ></v-img>

                <!-- for classified products when admin vefifiy any-->
                <v-img
                  v-if="product.StockQuantity && product.IsVerified"
                  :src="require('@/assets/images/verified.png')"
                  alt="Products"
                ></v-img>
              </v-flex>

              <v-flex
                class="my-product flex-grow-0 product-top-right"
                v-if="isOwnProduct"
              >
                My Product
              </v-flex>
              <v-flex class="flex-grow-0 product-top-right" v-else>
                <wish-list-btn
                  :is-favorite="product.IsFavorite"
                  :product-id="product.Id"
                  :is-elevation="true"
                ></wish-list-btn>

                <add-to-cart-btn
                  class="cart-icon"
                  v-if="product.HasAddToCart"
                  :cart-qty="product.CartQuantity"
                  :is-toggle="true"
                  :product="product"
                  :local-cartable-obj="localCarableObj"
                >
                  <v-icon>mdi-cart</v-icon>
                </add-to-cart-btn>
              </v-flex>
            </v-img>
            <v-flex class="product-bottom" v-if="product.SaleType == 'Auction'">
              <v-layout class="justify-space-between align-center bid-and-time">
                <v-sheet class="auction-time-holder">
                  <countdown
                    v-if="$root.getTime(product)"
                    :time="$root.getTime(product)"
                    :transform="$root.transformShorter"
                  >
                    <template slot-scope="props">
                      <v-icon
                        class="pr-1"
                        :class="$root.countDown.Color[product.AuctionStatus]"
                        size="20"
                        >{{
                          $root.countDown.Icon[product.AuctionStatus]
                        }}</v-icon
                      >
                      <b class="time-value">
                        {{ props.days }} {{ props.hours }} {{ props.minutes }}
                        {{ props.seconds }}
                      </b>
                    </template>
                  </countdown>
                  <span v-else>Ended</span>
                </v-sheet>

                <v-sheet
                  class="auction-bid-holder"
                  v-if="product.AuctionStatus !== 'Published'"
                >
                  <span class="bid-title">Bid:</span>
                  <span class="bid-value">
                    <strong>{{ product.BidCount }}</strong>
                  </span>
                </v-sheet>
              </v-layout>
            </v-flex>
          </v-flex>

          <v-card-subtitle
            class="product-sub-title d-flex justify-space-between font-weight-light"
          >
            <span v-text="product.CategoryName"></span>

            <v-layout class="flex-grow-0">
              <v-sheet
                v-if="product.IsPromoted"
                color="site_bg"
                class="px-1 mr-2 grey--text font-weight-light"
                rounded
                >{{ $t("promoted") }}</v-sheet
              >

              <v-sheet
                class="share-product-wrap share-product-grid"
                @mouseenter="openShareBtn = true"
                @mouseleave="openShareBtn = false"
              >
                <v-icon class="grey--text">mdi-share-variant</v-icon>

                <!-- Product-Social -->
                <v-flex class="share-productIn" v-if="openShareBtn">
                  <v-card class="share-product">
                    <product-social :meta-data="metaObj"></product-social>
                  </v-card>
                </v-flex>
              </v-sheet>
            </v-layout>
          </v-card-subtitle>

          <v-card-text class="text--primary pb-0">
            <v-flex class="product-information">
              <div class="product-title">
                <router-link
                  :class="{ 'brand--text': hover }"
                  :to="{
                    name: 'ProductDetails',
                    params: { id: product.Id, slug: product.Slug },
                  }"
                  v-text="product.ItemName"
                  role="link"
                  aria-label="click for details"
                ></router-link>
              </div>

              <div
                class="product-price-box d-flex"
                :class="!isRatingEnable ? '' : ''"
                v-if="product.SaleType == 'Auction'"
              >
                <v-sheet
                  v-if="product.BasePrice"
                  tag="span"
                  class="product-price"
                  v-text="
                    product.BidCount
                      ? $root.showAmount(product.MaxBidAmount)
                      : $root.showAmount(product.BasePrice)
                  "
                ></v-sheet>
                <v-sheet
                  v-if="product.BidCount"
                  tag="span"
                  class="bidding-status"
                  >Current Bid</v-sheet
                >
                <v-sheet v-else tag="span" class="bidding-status"
                  >Bid Start</v-sheet
                >
              </div>

              <div
                class="product-price-box d-flex"
                :class="!isRatingEnable ? '' : ''"
                v-else
              >
                <v-sheet
                  tag="span"
                  class="product-price"
                  v-text="$root.showAmount(product.SalesPrice)"
                ></v-sheet>
                <v-sheet
                  v-if="product.DiscountedPrice"
                  tag="span"
                  class="bidding-status text-decoration-line-through"
                  v-text="$root.showAmount(product.DiscountedPrice)"
                ></v-sheet>
              </div>
              <div v-if="isRatingEnable" class="d-flex rating">
                <v-rating
                  dense
                  readonly
                  half-increments
                  :value="product.AverageRating"
                  background-color="grey lighten-3"
                  color="yellow darken-3"
                  empty-icon="$ratingFull"
                  class="grey--text"
                  size="15"
                ></v-rating>

                <router-link
                  class="pointer"
                  :class="{ 'brand--text': hover }"
                  :to="{
                    name: 'ProductDetails',
                    params: { id: product.Id, slug: product.Slug },
                  }"
                  role="link"
                  aria-label="click for details"
                >
                  <span class="d-block ml-1">
                    {{ product.AverageRating.toFixed(1) }}
                    ({{ product.NoOfReview }})
                  </span>
                </router-link>
              </div>
            </v-flex>
          </v-card-text>

          <!-- Product bottom labels -->
          <div class="product-btm-labels">
            <v-sheet
              v-if="product.SaleType == 'Auction'"
              v-text="'Nilam'"
              dark
              color="brand"
              class="type font-weight-light text-center align-center"
            ></v-sheet>
            <v-sheet
              v-if="product.SaleType == 'Bargain'"
              v-text="'Bargain'"
              dark
              color="success"
              class="type font-weight-light text-center align-center"
            ></v-sheet>
          </div>
        </v-card>
      </template>
    </v-hover>
  </v-flex>
</template>

<script>
import ProductSocial from "@/components/sites/pages/ProductSocial";
import WishListBtn from "@/components/sites/common/WishListBtn";
import AddToCartBtn from "@/components/sites/common/AddToCartBtn";
export default {
  name: "ProductCard",
  components: {
    WishListBtn,
    ProductSocial,
    AddToCartBtn,
  },
  data() {
    return {
      colorsArr: {
        new: "silver_tree",
        renew: "silver_tree",
        used: "black",
        single: "grey",
        lot: "grey",
      },
      metaObj: {},
      openShareBtn: false,
      localCarableObj: {},
    };
  },
  computed: {
    productImgForScreeSize() {
      return this.$root.dimentions.thumb;
    },
    isOwnProduct() {
      if (
        this.$auth &&
        this.$auth.user &&
        this.$auth.user.UserId == this.product.UserId
      ) {
        return true;
      }
      return false;
    },
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
  },
  created() {
    this.setMetaObj();

    // use for adding cart after login redirect
    if (this.isLoggedIn) {
      let localCartableObj = JSON.parse(
        localStorage.getItem("local-cartable-obj")
      );
      this.localCarableObj = localCartableObj || {};
    }
  },
  methods: {
    setMetaObj() {
      this.metaObj.SocialLogoPath = this.$root.generateImageUrl(
        this.product,
        this.$root.dimentions.thumb
      );
      let ProductDetailsUrl = this.$store.state.Configs.getProductDetails
        .replace(":id", this.product.Id)
        .replace(":slug", this.product.Slug);

      this.metaObj.CurrentSocialShareUrl = ProductDetailsUrl;
    },
  },
  props: {
    product: Object,
    isRatingEnable: Boolean,
  },
};
</script>
<style lang="scss" scope>
@import "@/sass/product_grid_cart.scss";
</style>
