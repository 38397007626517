<template>
  <v-row>
    <v-col cols="12" v-if="loading">
      <v-skeleton-loader
        class="mx-auto"
        min-height="410"
        type="image,article"
      ></v-skeleton-loader>
    </v-col>
    <template v-else>
      <v-col
        cols="12"
        md="4"
        lg="3"
        sm="6"
        v-for="(data, index) in dataArr"
        :key="index"
      >
        <v-flex class="category-container">
          <h3 v-text="data.Title"></h3>

          <template
            v-if="
              data.HighlightSectionDetailDtos &&
              data.HighlightSectionDetailDtos.length
            "
          >
            <v-layout
              wrap
              class="category-container-in"
              :class="'item-' + data.HighlightSectionDetailDtos.length"
              v-if="data.HighlightSectionDetailDtos.length"
            >
              <v-flex
                class="category-container-box flex-grow-0"
                v-for="(subData, ind) in data.HighlightSectionDetailDtos"
                :key="ind"
              >
                <router-link
                  :to="subData.ImageLink"
                  :aria-label="subData.DocumentTitle"
                >
                  <v-img
                    contain
                    :alt="subData.DocumentTitle"
                    :src="$root.generateMediaImageUrl(subData.FilePath)"
                  ></v-img>
                  <div
                    class="product-top-left"
                    v-if="subData.Discount && subData.Discount > 0"
                  >
                    <div
                      class="discount"
                      v-text="'-' + subData.Discount + '%'"
                    ></div>
                  </div>
                </router-link>
              </v-flex>
            </v-layout>
          </template>
          <v-flex class="see-more-box">
            <router-link :to="data.DetailsLink" class="see-more"
              >To See More Items</router-link
            >
          </v-flex>
        </v-flex>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import { HomeBlockMixins } from "@/mixins/HomeBlockMixins";
export default {
  name: "Highlights",
  mixins: [HomeBlockMixins],
};
</script>

<style lang="scss" scoped>
@import "@/sass/category_section.scss";
</style>
