<template>
  <v-row>
    <v-flex v-if="loading">
      <v-skeleton-loader class="mx-auto" type="table-row"></v-skeleton-loader>
    </v-flex>
    <template v-else>
      <v-col v-for="(arr, index) in chunkArr" :key="index" cols="12" md="6" class="py-0">
        <v-row>
          <v-col v-for="(data, index) in arr" :key="index" cols="6" sm="3">
            <router-link
              :to="
                generateURl({
                  BrandId: data.Id,
                  OwnerId: data.OwnerId,
                })
              "
            >
              <v-flex class="brand-container">
                <v-flex class="brand-img">
                  <v-img
                    contain
                    :alt="data.BrandName + ' Logo'"
                    :src="$root.generateMediaImageUrl(data.ImageUrl)"
                    class="white"
                  ></v-img>
                  <v-flex class="flex-grow-0 brand-logo">
                    <v-img
                      contain
                      :alt="data.BrandName + ' Icon'"
                      :src="$root.generateMediaImageUrl(data.IconUrl)"
                    ></v-img>
                  </v-flex>
                </v-flex>
                <h3 class="text-truncate" v-text="data.BrandName"></h3>
              </v-flex>
            </router-link>
          </v-col>
        </v-row>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import { HomeBlockMixins } from "@/mixins/HomeBlockMixins";
export default {
  name: "Brands",
  mixins: [HomeBlockMixins],
  computed: {
    // Chunk the data coming into 3 cards per row. This is for CSS height reasons (makes everything even when downsizing to smaller or larger screens)
    chunkArr() {
      return this._.chunk(this.dataArr, 4);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/brands.scss";
</style>
