<template>
  <v-row>
    <v-col cols="12" v-if="loading">
      <v-skeleton-loader class="mx-auto" type="table-row"></v-skeleton-loader>
    </v-col>
    <template v-else>
      <v-col
        v-for="(category, index) in dataArr"
        :key="index"
        cols="6"
        md="3"
        sm="4"
        :lg="lg"
        class="pb-0 py-lg-3"
      >
        <router-link
          :to="
            generateURl(
              {
                ParentCategoryId: category.Id,
              },
              category.Name
            )
          "
        >
          <v-flex class="list_box d-flex align-center">
            <v-flex class="list-img flex-grow-0">
              <v-img
                max-width="26"
                max-height="26"
                :alt="category.Name"
                :src="$root.generateMediaImageUrl(category.HeaderIconUrl)"
              ></v-img>
            </v-flex>
            <h3 v-text="category.Name"></h3>
          </v-flex>
        </router-link>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import { HomeBlockMixins } from "@/mixins/HomeBlockMixins";
export default {
  name: "Categories",
  mixins: [HomeBlockMixins],
  props: {
    grid: Number,
  },
  computed: {
    lg() {
      if (this.grid == 6) {
        return 4;
      }
      return 2;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/category_list.scss";
</style>
