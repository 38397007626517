import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "../store";

// var _ = require("lodash");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/client-not-found",
    name: "clientError",
    component: () =>
      import(/* webpackChunkName: "components" */ "../views/client-error.vue"),
  },
  {
    path: "/product/search/:slug?",
    name: "ProductSearch",
    component: () =>
      import(
        /* webpackChunkName: "components" */ "../views/product-search.vue"
      ),
  },
  {
    path: "/product/details/:id/:slug",
    name: "ProductDetails",
    component: () =>
      import(
        /* webpackChunkName: "components" */ "../views/product-details.vue"
      ),
  },
  {
    path: "/post-product--step-1/:id?",
    name: "postProductStep1",
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "components" */ "../views/post-product-step-1.vue"
      ),
  },
  {
    path: "/post-product--step-2/:id?",
    name: "postProductStep2",
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "components" */ "../views/post-product-step-2.vue"
      ),
    beforeEnter(to, from, next) {
      var selected = JSON.parse(localStorage.getItem("postProductSelected"));
      if (selected && selected.CategoryId && to.params.id == selected.Id) {
        next();
      } else {
        //TODO:message modifiy
        store.commit("set_flash", {
          type: "error",
          message: "Please complete the step1 first.",
        });
        next({
          name: "postProductStep1", // back to safety route //
        });
      }
    },
  },
  // {
  //   path: "/post-product--step-3/:id?",
  //   name: "postProductStep3",
  //   meta: { requiresAuth: true },
  //   component: () =>
  //     import(/* webpackChunkName: "components" */ "../views/post-product-step-3.vue"),
  //   beforeEnter(to, from, next) {
  //     var selected = JSON.parse(localStorage.getItem("postProductSelected"));
  //     if (selected && selected.CategoryId && to.params.id == selected.Id) {
  //       if (selected.ItemName) {
  //         next()
  //       } else {
  //         store.commit("set_flash", {
  //           type: "error",
  //           message: "Please complete the step2 first",
  //         });
  //         next({
  //           name: "postProductStep2" // back to safety route //
  //         });
  //       }
  //     } else {
  //       //TODO:message modifiy
  //       store.commit("set_flash", {
  //         type: "error",
  //         message: "Please complete the step1 first",
  //       });
  //       next({
  //         name: "postProductStep1" // back to safety route //
  //       });
  //     }
  //   }
  // },
  {
    path: "/bidding/details/:id/:slug",
    name: "biddingDetails",
    component: () =>
      import(
        /* webpackChunkName: "components" */ "../views/bidding-details.vue"
      ),
  },

  {
    path: "/login",
    name: "Login",
    meta: { requiresGuest: true },
    component: () =>
      import(/* webpackChunkName: "components" */ "../views/login.vue"),
  },
  {
    path: "/logout",
    name: "Logout",
    beforeEnter(to, from, next) {
      if (!localStorage.getItem("auth")) {
        next({
          name: "Home",
        });
      } else {
        store.dispatch("logout").then(() => {
          next({
            name: "Login",
          });
        });
      }
    },
  },
  {
    path: "/register",
    name: "Register",
    meta: { requiresGuest: true },
    component: () =>
      import(/* webpackChunkName: "components" */ "../views/register.vue"),
  },
  {
    path: "/otp",
    name: "Otp",
    component: () =>
      import(/* webpackChunkName: "components" */ "../views/otp.vue"),
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    meta: { requiresGuest: true },
    component: () =>
      import(
        /* webpackChunkName: "components" */ "../views/forgot-password.vue"
      ),
  },
  {
    path: "/create-new-password/:username",
    name: "CreateNewPassword",
    component: () =>
      import(
        /* webpackChunkName: "components" */ "../views/create-new-password.vue"
      ),
  },

  //"=============Account Common Menu============
  {
    path: "/dashboard",
    name: "Dashboard",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "components" */ "../views/dashboard.vue"),
  },
  {
    path: "/my-account",
    name: "MyAccount",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "components" */ "../views/my-account.vue"),
  },
  {
    path: "/address",
    name: "Address",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "components" */ "../views/address.vue"),
  },
  {
    path: "/notification",
    name: "Notification",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "components" */ "../views/notification.vue"),
  },
  //"============Account Common Menu=============

  //"===============As a Seller============
  {
    path: "/received-order",
    name: "ReceivedOrder",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "components" */ "../views/my-order.vue"),
  },
  {
    path: "/received-bargain",
    name: "ReceivedBargain",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "components" */ "../views/my-bargain.vue"),
  },
  {
    path: "/my-brands",
    name: "MyBrands",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "components" */ "../views/my-brands.vue"),
  },
  {
    path: "/my-brand-details",
    name: "MyBrandDetails",
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "components" */ "../views/my-brand-details.vue"
      ),
  },
  {
    path: "/my-products",
    name: "MyProducts",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "components" */ "../views/my-products.vue"),
  },
  {
    path: "/campaign",
    name: "Campaign",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "components" */ "../views/campaign.vue"),
  },
  {
    path: "/all-campaign",
    name: "AllCampaign",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "components" */ "../views/all-campaign.vue"),
  },
  {
    path: "/campaign-details",
    name: "CampaignDetails",
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "components" */ "../views/campaign-details.vue"
      ),
  },
  {
    path: "/join-campaign",
    name: "JoinCampaign",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "components" */ "../views/join-campaign.vue"),
  },
  {
    path: "/posting-summary",
    name: "PostingSummary",
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "components" */ "../views/posting-summary.vue"
      ),
  },
  //"==============End As a Seller=========

  //"===============AS A CUSTOMER============
  {
    path: "/wish-list",
    name: "WishList",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "components" */ "../views/wish-list.vue"),
  },
  {
    path: "/checkout/:cartType?",
    name: "Checkout",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "components" */ "../views/checkout.vue"),
  },
  {
    path: "/my-auction",
    name: "MyAuction",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "components" */ "../views/my-auction.vue"),
  },
  {
    path: "/my-bargain",
    name: "MyBargain",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "components" */ "../views/my-bargain.vue"),
  },

  //"==============END AS A CUSTOMER=========

  {
    path: "/my-account-edit",
    name: "MyAccountEdit",
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "components" */ "../views/my-account-edit.vue"
      ),
  },
  {
    path: "/my-order",
    name: "MyOrder",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "components" */ "../views/my-order.vue"),
  },

  {
    path: "/order-details/:id",
    name: "MyOrderDetails",
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "components" */ "../views/my-order-details.vue"
      ),
  },

  {
    path: "/payment/:cartType?",
    name: "Payment",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "components" */ "../views/payment.vue"),
  },
  {
    path: "/contact-us",
    name: "Contact",
    component: () =>
      import(/* webpackChunkName: "components" */ "../views/contact-us.vue"),
  },
  {
    path: "/about-us",
    name: "AboutUs",
    component: () =>
      import(/* webpackChunkName: "components" */ "../views/about-us.vue"),
  },
  {
    path: "/faq",
    name: "Faq",
    component: () =>
      import(/* webpackChunkName: "components" */ "../views/faq.vue"),
  },
  {
    path: "/blog-posts",
    name: "Blog",
    component: () =>
      import(/* webpackChunkName: "components" */ "../views/blog.vue"),
  },
  {
    path: "/blog-post-details",
    name: "BlogPostDetails",
    component: () =>
      import(
        /* webpackChunkName: "components" */ "../views/blog-post-details.vue"
      ),
  },
  {
    path: "/payment-success/:id?",
    name: "PaymentSuccess",
    component: () =>
      import(
        /* webpackChunkName: "components" */ "../views/payment-success-or-fail.vue"
      ),
  },
  {
    path: "/payment-fail/:id?",
    name: "PaymentFail",
    component: () =>
      import(
        /* webpackChunkName: "components" */ "../views/payment-success-or-fail.vue"
      ),
  },
  {
    path: "/order-success",
    name: "OrderSuccess",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "components" */ "../views/order-success.vue"),
    beforeEnter(to, from, next) {
      var successOrderId = localStorage.getItem("successOrderId");
      if (successOrderId) {
        next();
      } else {
        //TODO:message modifiy
        store.commit("set_flash", {
          type: "error",
          message: "Please place an order first.",
        });
        next({
          name: "Home", // back to safety route //
        });
      }
    },
  },
  {
    path: "/add-address",
    name: "AddAddress",
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "components" */ "../components/sites/common/AddressForm.vue"
      ),
  },
  {
    path: "/pages/:id/:slug",
    name: "Pages",
    component: () =>
      import(/* webpackChunkName: "components" */ "../views/pages.vue"),
  },
  {
    path: "/congratulations/:slug",
    name: "Congratulation Page",
    meta: { notRequireHaderFooter: true },
    component: () =>
      import(/* webpackChunkName: "components" */ "../views/congratulation.vue"),
  },
  {
    path: "/*",
    component: () =>
      import(/* webpackChunkName: "components" */ "../views/not-found.vue"),
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  let token = localStorage.getItem("token");
  let auth = localStorage.getItem("auth");

  store.commit("update_meta", {
    PageTitle: to.name,
  });

  if (!token) {
    store.dispatch("getToken").then(() => {
      console.log("token feched");
    });
  }

  // for event layout without header footer
  if (to.matched.some((record) => record.meta.notRequireHaderFooter)) {
    to.meta.requireHaderFooter = false;
  } else {
    to.meta.requireHaderFooter = true;
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (token && auth) {
      if (JSON.parse(auth).AccountVerifiedAt) {
        next();
      } else {
        next({ name: "Otp" });
      }
      return false;
    }
    localStorage.setItem("loginRedirect", to.fullPath);
    next({ name: "Login" });
  } else {
    next();
  }
});

export default router;
