<template>
  <v-flex>
    <v-flex v-if="loading">
      <v-skeleton-loader class="mx-auto" type="image"></v-skeleton-loader>
    </v-flex>
    <template v-else>
      <v-flex class="home-carousel" v-if="bannerType == 'slider'">
        <v-hover v-slot:default="{ hover }">
          <v-carousel :cycle="hover ? false : true" :height="calHeight">
            <v-carousel-item v-for="slide in dataArr" :key="slide.id">
              <single-banner
                :banner-type="bannerType"
                :banner-obj="slide"
                :height="calHeight"
                class="banner-wrapper"
              ></single-banner>
            </v-carousel-item>
          </v-carousel>
        </v-hover>
      </v-flex>
      <v-flex
        v-else-if="
          bannerType == 'middlepage' ||
          bannerType == 'bottompage' ||
          bannerType == 'other'
        "
      >
        <v-carousel
          cycle
          :show-arrows="Boolean(Number(dataArr.length - 1))"
          :hide-delimiters="true"
          height="auto"
        >
          <v-carousel-item v-for="slide in dataArr" :key="slide.id">
            <single-banner
              :banner-type="bannerType"
              :banner-obj="slide"
            ></single-banner>
          </v-carousel-item>
        </v-carousel>
      </v-flex>
      <v-flex v-else-if="bannerType == 'single'">
        <single-banner
          :banner-type="bannerType"
          :banner-obj="dataArr"
        ></single-banner>
      </v-flex>
    </template>
  </v-flex>
</template>

<script>
import { HomeBlockMixins } from "@/mixins/HomeBlockMixins";
import SingleBanner from "@/components/sites/common/SingleBanner";

export default {
  name: "HomeBanner",
  components: {
    SingleBanner,
  },
  mixins: [HomeBlockMixins],
  data() {
    return {
      cycle: true,
    };
  },
  computed: {
    bannerType() {
      if (this.url.includes("banner")) {
        let type = this.url.split("/").at(-1).toLowerCase();
        if (isNaN(type)) {
          return type;
        } else {
          return "single";
        }
      }
      return "";
    },
    calHeight() {
      if (this.$root.windowSize > 920) {
        return this.$root.windowSize / 6; //4.3
      } else {
        return this.$root.windowSize / 2;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/home_banner.scss";
</style>
