<template>
  <v-select
    dark
    style="width: 70px"
    background-color="transparent"
    solo
    flat
    hide-details
    v-model="selectedLangObj"
    :items="langs"
    @change="setLang(selectedLangObj.name)"
  >
    <template v-slot:selection="{ item }">
      {{ item.title }}
      <!-- <v-img max-width="22" class="mr-1" :src="require('@/assets/images/flag/' + item.image)"></v-img> -->
    </template>
    <template v-slot:item="{ item }">
      <v-img
        max-width="22"
        class="mr-1"
        :alt="item.title"
        :src="require('@/assets/images/flag/' + item.image)"
      ></v-img>
      {{ item.title }}
    </template>
  </v-select>
</template>

<script>
import axios from "axios";

export default {
  name: "Language",

  computed: {
    langs: function () {
      return this.$store.state.Configs.langs;
    },
    lang: function () {
      return this._.find(this.langs, ["name", this.$store.getters.lang]);
    },
  },
  data() {
    return {
      selectedLangObj: {},
    };
  },
  methods: {
    setLang(lang) {
      this.$store.commit("set_lang", lang);
      axios.defaults.headers.common["Accept-Language"] = lang;
      this.$i18n.locale = lang.substring(0, 2);
    },
  },
  created() {
    this.selectedLangObj = this.lang;
  },
};
</script>
