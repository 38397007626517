export const APIROOT = process.env.VUE_APP_API_ROOT;

const APIROOT_WITH_PREFIX_V1 = APIROOT + "api/v1/";
const APIROOT_WITH_PREFIX_V2 = APIROOT + "api/v2/";

export const hubUrl = APIROOT + "notificationhub";
export const getToken = APIROOT_WITH_PREFIX_V1 + "token";
export const siteSettings = APIROOT_WITH_PREFIX_V1 + "sitesetting/get";
export const getItemGroups = APIROOT_WITH_PREFIX_V1 + "item-group/getall";
export const register = APIROOT_WITH_PREFIX_V1 + "register-user";
export const sendOtp = APIROOT_WITH_PREFIX_V1 + "send-otp";
export const sendOtpForChange = APIROOT_WITH_PREFIX_V1 + "send-otp-for-change";
export const validateOtp = APIROOT_WITH_PREFIX_V1 + "validate-otp";
export const changedPassword = APIROOT_WITH_PREFIX_V1 + "changed-password";

export const getAllBlockApis = APIROOT_WITH_PREFIX_V2 + "applicationapi/getall";

export const getRelatedProducts =
  APIROOT_WITH_PREFIX_V1 + "item/get-related-products/:id";

export const getAllParentCategories =
  APIROOT_WITH_PREFIX_V1 + "item-category/get-all-parent-categories";

export const getAllCategories = APIROOT_WITH_PREFIX_V1 + "item-category/getall";

export const toggleFavorite = APIROOT_WITH_PREFIX_V1 + "item/favorite/:id";
export const getFavoriteProducts =
  APIROOT_WITH_PREFIX_V1 + "item/my-favorite-products";
export const getProductDetails =
  APIROOT_WITH_PREFIX_V1 + "item/get-details/:id/:slug";
export const getProducts = APIROOT_WITH_PREFIX_V1 + "item/search-public";
export const getProductDetailsForEdit =
  APIROOT_WITH_PREFIX_V1 + "item/details/:id";
export const saveProduct = APIROOT_WITH_PREFIX_V1 + "item/save";
export const productDelete = APIROOT_WITH_PREFIX_V1 + "item/delete/:id";
export const itemReviewSubmit = APIROOT_WITH_PREFIX_V1 + "item-review/add";
export const getIemReview = APIROOT_WITH_PREFIX_V1 + "item-review/get/:id";
export const getIemReviewSummary =
  APIROOT_WITH_PREFIX_V1 + "item-review/get-review-summary/:id";
export const getIemSearchView =
  APIROOT_WITH_PREFIX_V1 + "item-review/search-review";
export const updateItemSalesPrice =
  APIROOT_WITH_PREFIX_V1 + "item/update-sales-price";
export const updateItemQuantity =
  APIROOT_WITH_PREFIX_V1 + "item/update-stock-quantity";
export const updateItemDiscount =
  APIROOT_WITH_PREFIX_V1 + "item/update-discount";
export const getItemDiscountByItemId =
  APIROOT_WITH_PREFIX_V1 + "item/get-item-discount/:id";

export const placeBid = APIROOT_WITH_PREFIX_V1 + "bidding/place-bid";
export const getAllBid = APIROOT_WITH_PREFIX_V1 + "bidding/getall/:auctionId";
export const addAutomaticBid =
  APIROOT_WITH_PREFIX_V1 + "bidding/add-automatic-bid";
export const deleteAutomaticBid =
  APIROOT_WITH_PREFIX_V1 + "bidding/delete-automatic-bid/:auctionId";

export const addAddress = APIROOT_WITH_PREFIX_V1 + "address/add";
export const getShippingAddress = APIROOT_WITH_PREFIX_V1 + "address/getall";
export const getByIdAddress = APIROOT_WITH_PREFIX_V1 + "address/get/:id";
export const updateAddress = APIROOT_WITH_PREFIX_V1 + "address/update";
export const markAsDefaultAddress =
  APIROOT_WITH_PREFIX_V1 + "address/mark-as-default/:id";
export const deleteAddress = APIROOT_WITH_PREFIX_V1 + "address/delete/:id";

export const addContact = APIROOT_WITH_PREFIX_V1 + "contact-us/add";

export const addInquiry = APIROOT_WITH_PREFIX_V1 + "contact-us/add-inquiry";

export const allFaqCategories = APIROOT_WITH_PREFIX_V1 + "faq-category/getall";
export const allFaqs = APIROOT_WITH_PREFIX_V1 + "faqs/getall";

export const getPageInfoById = APIROOT_WITH_PREFIX_V1 + "pages/get/:id";

export const currentUserUrl = APIROOT_WITH_PREFIX_V1 + "getcurrentuser";

export const getUserProfile = APIROOT_WITH_PREFIX_V1 + "user/profile";
export const getUserBalance = APIROOT_WITH_PREFIX_V1 + "user/balance";
export const updateUserProfile = APIROOT_WITH_PREFIX_V1 + "user/update-profile";
export const addMedia = APIROOT_WITH_PREFIX_V1 + "media/add";
export const getMyProducts = APIROOT_WITH_PREFIX_V1 + "item/search-my-items";
export const getMyProductSummary =
  APIROOT_WITH_PREFIX_V1 + "item/my-items-summary";
export const getMyParticipatedAuction =
  APIROOT_WITH_PREFIX_V1 + "item/search-my-participated-auction";
export const getMyBargainItem =
  APIROOT_WITH_PREFIX_V1 + "bargain/search-my-bargain-items";
export const sendBargainPrice = APIROOT_WITH_PREFIX_V1 + "bargain/send-proposal";
export const getBargainInfoByItemId =
  APIROOT_WITH_PREFIX_V1 + "bargain/bargain-info/:itemId";
export const getBargainDetailsById =
  APIROOT_WITH_PREFIX_V1 + "bargain/bargain-details/:id";
export const cancelBargain =
  APIROOT_WITH_PREFIX_V1 + "bargain/cancel-bargain/:id";
export const acceptBargainAmount =
  APIROOT_WITH_PREFIX_V1 + "bargain/accept-proposal/:id";
export const getBargainItemForOrder =
  APIROOT_WITH_PREFIX_V1 + "bargain/get-bargain-item-for-order";

export const reAuction = APIROOT_WITH_PREFIX_V1 + "item/re-auction/:id";
export const sendOrderRequest =
  APIROOT_WITH_PREFIX_V1 + "item/send-order-request/:id";

export const getMyOrders = APIROOT_WITH_PREFIX_V1 + "order/search-my-orders";
export const getMyOrderDetails = APIROOT_WITH_PREFIX_V1 + "order/details/:id";

export const orderUpdateStatus =
  APIROOT_WITH_PREFIX_V1 + "order/update-order-status";

export const getDivisions = APIROOT_WITH_PREFIX_V1 + "division/getall";
export const getDistricts = APIROOT_WITH_PREFIX_V1 + "district/getall";
export const getDistrictsByDivision =
  APIROOT_WITH_PREFIX_V1 + "district/getall-by-division/:divisionId";

export const getMediaImages =
  APIROOT_WITH_PREFIX_V1 + "media/getall/:type/:refId";
export const deleteMediaImage = APIROOT_WITH_PREFIX_V1 + "media/delete/:id";
export const markAsCover = APIROOT_WITH_PREFIX_V1 + "media/cover/:id";

export const getAuctionPolicy = APIROOT_WITH_PREFIX_V1 + "auction-policy/get";
export const getMeasurements = APIROOT_WITH_PREFIX_V1 + "measurement/getall";

export const getPaymentGatewayURL = APIROOT_WITH_PREFIX_V1 + "payment/gateway";
export const getMyPaymentInfo = APIROOT_WITH_PREFIX_V1 + "payment/get/:id";

export const placeOrder = APIROOT_WITH_PREFIX_V1 + "order/place-order";
export const getShippingCost =
  APIROOT_WITH_PREFIX_V1 + "order/get-shipping-cost";

export const addToCart = APIROOT_WITH_PREFIX_V1 + "cart/add";
export const getAllCarts = APIROOT_WITH_PREFIX_V1 + "cart/getAll";
export const removeFromCart = APIROOT_WITH_PREFIX_V1 + "cart/remove";
export const addPromoCodeToCart =
  APIROOT_WITH_PREFIX_V1 + "cart/add-promo-code";
export const removePromoCode =
  APIROOT_WITH_PREFIX_V1 + "cart/remove-promo-code";
export const getAllNotification =
  APIROOT_WITH_PREFIX_V1 + "notification/getall";
export const notificaionMarkAllAsRead =
  APIROOT_WITH_PREFIX_V1 + "notification/mark-all-read";

export const getCategoryWiseAttribute =
  APIROOT_WITH_PREFIX_V1 +
  "item-category/get-category-attribute/:categoryId/:itemId";

export const getMetaTags =
  APIROOT_WITH_PREFIX_V1 + "item/search-meta-keywords/:categoryId";

export const categoryGetallById =
  APIROOT_WITH_PREFIX_V1 + "item-category/getall-by-id/:ParentId";

export const getMostSellingProducts =
  APIROOT_WITH_PREFIX_V1 + "item/get-mostselling-products";

export const updateCartItems =
  APIROOT_WITH_PREFIX_V1 + "cart/update-cart-items";
export const getAllBrands = APIROOT_WITH_PREFIX_V1 + "brand/getall";
export const getMyBrands =
  APIROOT_WITH_PREFIX_V1 + "brand/get-my-brands/:ownerId";
export const getBrandInfos = APIROOT_WITH_PREFIX_V1 + "brand/get/:brandId";
export const getCompanyInfos = APIROOT_WITH_PREFIX_V1 + "user/get-user-company-info/:ownerId";
export const getPickupPoint = APIROOT_WITH_PREFIX_V1 + "address/getall-pickup-point";

export const getAllPaymentGateway = APIROOT_WITH_PREFIX_V2 + 'payment-gateway/getall';

export const userCampaignEvent = APIROOT_WITH_PREFIX_V1+'user/add-user-campaign-event';


