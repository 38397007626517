<template>
  <v-flex
    class="maintanence h-100 w-100 d-flex flex-wrap align-center justify-center flex-column"
    :style="{
      background:
        'url(' + require('@/assets/images/maintanence_bg.jpg') + ') center top',
    }"
  >
    <v-flex class="logo-container">
      <img
        class="flex-grow-0"
        alt="Nilam Logo"
        :lazy-src="require('@/assets/images/logo-bg.png')"
        :src="require('@/assets/images/logo.png')"
      />
    </v-flex>

    <!-- <h1>Nilam is upgrading.</h1>
    <p>We expect to be back soon. Thanks for your patience.</p> -->
    <h1>Lost internet connection or down for maintanence</h1>
    <p>
      Please check your connection or We expect to be back in a couple of
      minutes. Thanks for your patience.
    </p>
    <img :src="require('@/assets/images/maintanence_pic.png')" />
  </v-flex>
</template>

<script>
export default {
  name: "Maintenance",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/app/index.scss";

.maintanence {
  position: relative;
  text-align: center;
  padding: rem-calc(60px 15px 0 15px);
  .logo-container {
    width: 100%;
    padding: 5px 0;
    position: absolute;
    left: 0;
    top: 0;
    background-color: map-get($colors, primary);
    img {
      display: block;
      padding: 0;
      margin: 0 auto;
    }
  }
  h1 {
    padding-bottom: rem-calc(15px);
    @include font(false, 48px, 54px, primary);
  }
  p {
    @include font(false, 20px, 32px, grey);
  }
  img {
    max-width: 100%;
    padding-top: rem-calc(50px);
  }
}
@include media(md) {
  .maintanence {
    margin-top: -50px;
  }
}

@include media(sm) {
  .maintanence {
    h1 {
      @include font(false, 26px, 34px, primary);
    }
    p {
      @include font(false, 18px, 24px, false);
    }
  }
}
</style>
