<template>
  <div>
    <footer
      padless
      class="d-block site-footer"
      v-if="Object.keys(siteSettings).length"
    >
      <router-link class="add-post" :to="{ name: 'postProductStep1' }">
        <span><v-icon>mdi mdi-plus</v-icon> Post Your Ad</span>
      </router-link>

      <v-layout class="footer-top">
        <v-container>
          <v-row>
            <v-col class="footer-box">
              <!-- require('@/assets/images/logo.png') -->
              <router-link :to="{ name: 'Home' }">
                <v-img
                  contain
                  max-width="137"
                  max-height="29"
                  class="flex-grow-0 mb-6"
                  :src="
                    $root.generateMediaImageUrl(
                      siteSettings.LogoPath,
                      'logo-bg.png'
                    )
                  "
                  alt="Nilam Logo"
                ></v-img>
              </router-link>

              <h3>Get in Touch</h3>
              <v-flex class="pt-5 footer-social d-flex">
                <a
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  v-if="siteSettings.SocialAddress.FacebookURL"
                  :href="siteSettings.SocialAddress.FacebookURL"
                >
                  <v-btn fab outlined text tile aria-label="icon">
                    <v-icon>mdi-facebook</v-icon>
                  </v-btn>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  v-if="siteSettings.SocialAddress.YoutubeURL"
                  :href="siteSettings.SocialAddress.YoutubeURL"
                >
                  <v-btn fab outlined text tile aria-label="icon">
                    <v-icon>mdi-youtube</v-icon>
                  </v-btn>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  v-if="siteSettings.SocialAddress.LinkedinURL"
                  :href="siteSettings.SocialAddress.LinkedinURL"
                >
                  <v-btn fab outlined text tile aria-label="icon">
                    <v-icon>mdi-linkedin</v-icon>
                  </v-btn>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  v-if="siteSettings.SocialAddress.TwitterURL"
                  :href="siteSettings.SocialAddress.TwitterURL"
                >
                  <v-btn fab outlined text tile aria-label="icon">
                    <v-icon>mdi-twitter</v-icon>
                  </v-btn>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  v-if="siteSettings.SocialAddress.InstagramURL"
                  :href="siteSettings.SocialAddress.InstagramURL"
                >
                  <v-btn fab outlined text tile aria-label="icon">
                    <v-icon>mdi-instagram</v-icon>
                  </v-btn>
                </a>
              </v-flex>
              <v-flex class="e-cab">
                <h5>Member of e-Cab</h5>
                <v-img
                  class="mt-3"
                  max-width="65"
                  contain
                  :src="require('@/assets/images/e-cab.png')"
                  alt="e-cab"
                ></v-img>
              </v-flex>
            </v-col>

            <v-col
              class="footer-box"
              v-for="(menus, catName) in footerMenus"
              :key="menus.Id"
            >
              <h3 v-text="catName"></h3>
              <v-flex>
                <ul>
                  <li v-for="(menu, index) in menus" :key="index">
                    <router-link
                      v-if="menu.PageType == 'Webpage'"
                      :to="{
                        name: 'Pages',
                        params: { id: menu.Id, slug: menu.Slug },
                      }"
                    >
                      <span v-text="menu.PageTitle"></span>
                    </router-link>
                    <router-link
                      v-if="menu.Link && menu.PageType == 'Functional'"
                      :to="menu.Link"
                      exact-path
                    >
                      <span v-text="menu.PageTitle"></span>
                    </router-link>
                    <a
                      v-if="menu.Link && menu.PageType == 'ThirdParty'"
                      :href="menu.Link"
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                      v-text="menu.PageTitle"
                    ></a>
                  </li>
                </ul>
              </v-flex>
            </v-col>
          </v-row>
        </v-container>
      </v-layout>

      <v-layout class="footer-btm">
        <v-container>
          <v-layout justify-space-between class="flex-wrap">
            <!-- <v-img
              max-width="1140"
              contain
              :src="require('@/assets/images/payment-methods.png')"
              alt="Payment Methods"
            ></v-img> -->

            <picture
              ><source
                media="(max-width: 799px)"
                class="mw-100"
                :srcset="
                  require('@/assets/images/footer-payment-methods-mobile.png')
                " />
              <img
                :src="require('@/assets/images/footer-payment-methods.png')"
                alt="about"
                class="mw-100"
            /></picture>

            <span class="copy d-block">
              <span
                v-text="
                  siteSettings.SiteCopyRight || 'A Company of BlueBees Limited'
                "
              ></span>
              &copy; Copyright
              <router-link :to="{ name: 'Home' }">
                <span v-text="siteSettings.SiteTitle"></span>
              </router-link>
              {{ new Date().getFullYear() }}
            </span>
          </v-layout>
        </v-container>
      </v-layout>
    </footer>
    <seo-footer></seo-footer>
  </div>
</template>
<script>
import SeoFooter from "@/components/sites/common/SeoFooter";
export default {
  name: "SiteFooter",
  components: {
    SeoFooter,
  },
  data() {
    return {};
  },
  computed: {
    siteSettings() {
      return this.$store.state.siteSettings;
    },
    footerMenus() {
      return this._.groupBy(this.siteSettings.FooterMenus, "CategoryName");
    },
  },
};
</script>

<style lang="scss">
@import "@/sass/footer.scss";
</style>
