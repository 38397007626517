<template>
  <v-btn
    :disabled="disabled || loading || isEmi"
    :loading="loading"
    :class="classObj"
    @click="addOrRemove"
    aria-label="Add To Cart"
    role="button"
  >
    <slot>
      <span v-if="disabled">Added In Cart</span>
      <span v-else v-text="$t('Add To Cart')"></span>
    </slot>
  </v-btn>
</template>
<script>
export default {
  data: () => ({
    loading: false,
    CartType: "Ecommerce",
    CartQuantity: 1,
    isCarted: false, // for list item
    disabled: false, // for wish list
    isIncreasable: true,
  }),
  props: {
    product: Object,
    isToggle: Boolean,
    isDisable: Boolean,
    cartQty: Number,
    selectedAttributes: Array,
    isEmi: Boolean,
    pageName: String,
    localCartableObj: Object,
  },
  computed: {
    classObj() {
      return {
        "brand white--text": this.isToggle && this.isCarted,
        "v-btn--fab v-btn--round elevation-2": this.isToggle,
        "brand white--text text-none v-btn--block v-btn--depressed v-btn--tile":
          !this.isToggle,
      };
    },
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
  },
  created() {
    this.isCarted = !!this.cartQty;

    if (this.isDisable && this.isCarted) {
      this.disabled = true;
    }

    this.Fire.$on("qtyUpdate", (val) => {
      if (this.product.Id == val.id) {
        this.CartQuantity = val.qty;
      }
    });

    if (
      this.isLoggedIn &&
      this.localCartableObj &&
      this.localCartableObj.ItemId &&
      this.product.Id == this.localCartableObj.ItemId
    ) {
      this.addToCart(this.localCartableObj);
    }
  },
  beforeDestroy() {
    this.Fire.$off("qtyUpdate");
  },
  methods: {
    addOrRemove() {
      let cartableObj = {
        ItemId: this.product.Id,
        Quantity: this.CartQuantity,
        Stock: this.product.StockQuantity,
        IsIncreasable: true,
        AttributesInfo: this.selectedAttributes,
      };

      if (this.pageName && this.pageName == "details") {
        cartableObj.IsSelected = true;
      }

      let check = this.$root.checkAuth(this.$route.fullPath, cartableObj);
      if (check) return;

      if (this.isToggle && this.isCarted) {
        this.removeProduct();
      } else {
        this.addToCart(cartableObj);
      }
    },
    addToCart(cartableObj) {
      if (this.loading) return;

      let cartItems = JSON.parse(localStorage.getItem("app-carts"));

      if (cartableObj.Stock <= 0) {
        alert("Stock not available.");
        return;
      }

      if (cartableObj.Quantity < 1) {
        alert("Cart quantity must be 1 or higher");
        return;
      }

      if (cartItems && cartItems.Ecommerce.length) {
        var cartObj = this._.find(cartItems.Ecommerce, [
          "ItemId",
          cartableObj.ItemId,
        ]);
        if (
          cartObj &&
          cartableObj.Quantity > cartObj.StockQuantity - cartObj.CartQuantity
        ) {
          alert("Stock not available.");
          return;
        }
      }

      // pixel event for addToCard Start
      window.fbq("track", "AddToCart", {
        content_ids: [parseInt(this.product.Id)],
        content_type: "product",
        value: this.product.SalesPrice,
        currency: "BDT",
      });
      // pixel event for addToCard End

      this.loading = true;

      this.$root
        .syncHttp(this.$store.state.Configs.addToCart, cartableObj)
        .then((res) => {
          localStorage.removeItem("local-cartable-obj"); // remove after adding cart for login redirect

          this.$store
            .dispatch("updateCards", res.data.Items.ResponseData)
            .then(() => {
              this.loading = false;
              this.isCarted = true;
              if (this.isDisable) {
                this.disabled = true;
              }
              this.Fire.$emit("addedInCart", cartableObj.Quantity);
              this.CartQuantity = 1;
            })
            .then(() => {
              if (this.pageName && this.pageName == "details") {
                this.$router
                  .push({ name: "Checkout", params: { cartType: "ecommerce" } })
                  .catch(() => {});
              }
            })
            .catch(() => {
              this.loading = false;
            });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    removeProduct() {
      if (this.loading) return;

      this.loading = true;

      this.$store
        .dispatch("deleteFromCarts", {
          ItemId: this.product.Id,
          CartType: this.CartType,
        })
        .then(() => {
          this.loading = false;
          this.isCarted = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
