export var SlugifyMixin = {
    methods: {
        slugify(text) {
            if (!text) return null;
            return text.toString().trim().toLowerCase()
                .replace(/\s+/g, '-') // Replace spaces with -
                .replace(/&/g, '-and-') // Replace & with 'and'
                .replace(/[^\w-]+/g, '')// Remove all non-word chars
                .replace(/--+/g, '-'); // Replace multiple - with single -
        }
    }
}