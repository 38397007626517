<template>
  <v-layout class="flex site-content-wrap site_bg" v-if="description">
    <v-container>
      <h4 class="pb-4" v-text="title"></h4>
      <p
        style="column-count: 4; column-rule: 1px solid #ddd"
        v-html="description"
      ></p>
    </v-container>
  </v-layout>
</template>
<script>
export default {
  name: "SeoFooter",
  computed: {
    title() {
      return this.$store.state.metaObj.PageTitle;
    },
    description() {
      return this.$store.state.seoFooterDescription;
    },
  },
};
</script>
