import { SlugifyMixin } from "@/mixins/SlugifyMixin";

export var HomeBlockMixins = {
  mixins: [SlugifyMixin],
  data() {
    return {
      loading: false,
      dataArr: [],
    };
  },
  props: {
    url: String,
  },
  created() {
    this.getData();
  },
  methods: {
    generateURl(queryObj, routeParam) {
      let resolved = this.$router.resolve({
        name: "ProductSearch",
        params: { slug: this.slugify(routeParam) },
        query: queryObj,
      });
      return resolved.href;
    },
    getData() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      this.$http
        .post(this.$store.state.Configs.APIROOT + this.url)
        .then((result) => {
          this.dataArr = result.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },
};
