<template>
  <!-- Product section -->

  <div class="share-product-box" v-if="Object.keys(options).length">
    <ShareNetwork
      v-for="social in socials"
      :key="social.name"
      :network="social.class"
      :url="currentRoutePath"
      :title="options.MetaTitle || appName"
    >
      <v-icon :class="social.class">{{ social.name }}</v-icon>
    </ShareNetwork>
  </div>
</template>

<script>
export default {
  name: "ProductSocial",
  data() {
    return {
      options: {},
      appName: process.env.VUE_APP_TITLE,
    };
  },
  props: {
    metaData: Object,
  },
  computed: {
    currentRoutePath() {
      if (this.metaData && this.metaData.CurrentSocialShareUrl) {
        return this.metaData.CurrentSocialShareUrl;
      }
      return this.metaObj.SiteUrl + this.$route.path;
    },
    metaObj() {
      return this.$store.state.metaObj;
    },
    socials() {
      return this.$store.state.Configs.socials;
    },
  },
  mounted() {
    this.options = { ...this.metaObj, ...this.metaData };
  },
};
</script>
<style lang="scss" scoped>
.share-product-box {
  a {
    .v-icon.v-icon {
      font-size: 20px;
    }
    line-height: 30px;
    display: block;
    text-align: center;
    border-bottom: 1px solid #ebebeb;
    .facebook {
      color: #3b5999;
    }
    .pinterest {
      color: #d43232;
    }
    .linkedin {
      color: #0077b5;
    }
    .instagram {
      color: #a78966;
    }
    .twitter {
      color: #03a9f4;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}
</style>
