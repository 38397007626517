<template>
  <v-flex class="select-box flex-grow-0">
    <v-select
      dark
      solo
      hide-details
      append-icon="mdi-chevron-down"
      item-text="Name"
      label="Location"
      background-color="header_footer"
      @change="changeLocation(selectedLocation)"
      v-model="selectedLocation"
      :items="locations"
    ></v-select>
  </v-flex>
</template>

<script>
export default {
  name: "Location",
  data() {
    return {
      selectedLocation: "",
    };
  },
  computed: {
    locations: function () {
      return this.$store.state.locations;
    },
  },
  methods: {
    changeLocation(location) {
      if (!location) return;

      this.$router.push({
        name: "ProductSearch",
        query: { Location: location },
      });

      return;
    },
  },
};
</script>
