<template>
  <v-flex class="head-search">
    <v-flex class="input-wrap pb-0">
      <v-text-field
        solo
        flat
        append-icon
        v-model="keyword"
        @input="keywordTyping"
        color="white"
        hide-no-data
        hide-selected
        :placeholder="$t('Start typing to Search')"
        return-object
        hide-details
        @keydown.enter.prevent="
          routeTo({ name: 'ProductSearch', query: { Keyword: keyword } })
        "
      ></v-text-field>
    </v-flex>
    <button
      @click="routeTo({ name: 'ProductSearch', query: { Keyword: keyword } })"
      class="search-btn d-flex align-center justify-center"
    >
      <v-icon>mdi-magnify</v-icon>
      <!-- <span class="pl-1">{{ $t("search") }}</span> -->
    </button>

    <v-expand-transition v-if="isLoading">
      <v-flex class="matched-wrap pb-0">
        <v-progress-linear
          indeterminate
          color="yellow darken-2"
        ></v-progress-linear>
      </v-flex>
    </v-expand-transition>
    <v-expand-transition v-else-if="keyword && entries.length">
      <v-flex class="matched-wrap">
        <v-layout class="justify-space-between">
          <v-subheader
            >{{ entries.length }} of {{ count }} products</v-subheader
          >
          <v-subheader>
            <a
              @click="
                routeTo({ name: 'ProductSearch', query: { Keyword: keyword } })
              "
              class="brand--text"
              >View all</a
            >
          </v-subheader>
        </v-layout>

        <ul>
          <li v-for="product in entries" :key="product.Id">
            <v-flex class="item-image flex-grow-0">
              <img
                width="10"
                alt="nilam product thumb img"
                :src="$root.generateImageUrl(product, $root.dimentions.thumb)"
              />
            </v-flex>
            <v-flex class="item-info">
              <a
                @click="
                  routeTo({
                    name: 'ProductDetails',
                    params: { id: product.Id, slug: product.Slug },
                  })
                "
                class="item-name"
                >{{ product.ItemName }}</a
              >
              <v-sheet tag="div" class="item-meta"
                >Condition: {{ product.Condition }}</v-sheet
              >
            </v-flex>
            <v-flex class="item-price">
              {{ $root.showAmount(product.SalesPrice) }}
            </v-flex>
          </li>
        </ul>
      </v-flex>
    </v-expand-transition>
    <v-expand-transition
      v-else-if="keyword && !entries.length && !noItemHidden"
    >
      <v-flex class="matched-wrap">
        <v-layout class="justify-space-between">
          <v-subheader>No item found.</v-subheader>
          <v-subheader>
            <a @click="routeTo({ name: 'ProductSearch' })" class="brand--text"
              >View all</a
            >
          </v-subheader>
        </v-layout>
      </v-flex>
    </v-expand-transition>
  </v-flex>
</template>

<script>
import { debounce } from "lodash";
export default {
  data: () => ({
    descriptionLimit: 60,
    noItemHidden: true,
    isLoading: false,
    keyword: "",
    count: 0,
    entries: [],
  }),
  methods: {
    keywordTyping: debounce(function (val) {
      if (!val) {
        this.keyword = "";
      } else {
        this.Keyword = val;
        this.getData(this.Keyword);
      }
    }, 600),
    routeTo(obj) {
      this.entries = [];
      this.keyword = "";
      this.noItemHidden = true;
      this.$router.replace(obj).catch(() => {});
      return;
    },
    getData(kewords) {
      if (this.isLoading) return;

      this.isLoading = true;
      this.noItemHidden = false;
      this.entries = [];

      this.$http
        .post(this.$store.state.Configs.getProducts, {
          Keyword: kewords,
          Page: 1,
          Length: 10,
        })
        .then((result) => {
          this.count = result.data.recordsTotal;
          this.entries = result.data.data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
