<template>
  <v-flex>
    <v-flex v-for="(item, i) in menuArr" :key="group + '-' + i">
      <v-list-item
        :to="{ name: `${item.link}`, params: item.params }"
        v-if="!item.sellerOnly || isSeller == item.sellerOnly"
      >
        <v-list-item-icon class="mr-2">
          <v-icon size="19" v-text="item.icon"></v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="item.text"></v-list-item-title>
        </v-list-item-content>
        <span v-if="item.count" v-text="profileObj[item.count]"></span>
      </v-list-item>
    </v-flex>
  </v-flex>
</template>
<script>
export default {
  name: "MenuRender",
  props: {
    group: String,
    menuArr: Array,
  },
  computed: {
    profileObj() {
      return this.$store.state.profileObj;
    },
    isSeller() {
      if (this.$auth && this.$auth.user) {
        return this.$auth.user.IsVerified;
      }
      return false;
    },
  },
};
</script>
